import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";

export default function Food() {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [foodItemList, setFoodItemList] = useState([]);
  const [foodMealTypeList, setFoodMealTypeList] = useState([]);
  const [foodPackageList, setFoodPackageList] = useState([]);
  const [foodPackage, setFoodPackage] = useState({
    meal_type_id: "",
    food_items_ids: [],
    meal_package_unit_price: "",
  });
  console.log("foodPackage", foodPackage?.meal_type_id);

  const convertObject = (originalObject) => {
    const { meal_type_id, meal_type_name, food_items, per_meal_package_price } =
      originalObject;

    const mealTypeObject = {
      label: meal_type_name,
      value: meal_type_id,
    };

    const foodItemsArray = food_items.map((foodItem) => ({
      label: foodItem.food_item_name,
      value: foodItem.food_item_id,
    }));

    const convertedObject = {
      meal_type_id: mealTypeObject,
      food_items_ids: foodItemsArray,
      meal_package_unit_price: per_meal_package_price,
    };

    return convertedObject;
  };

  const getfoodItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_FOOD_ITEM_LIST_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.food_items);
        setFoodItemList(res?.data?.food_items);
      })
      .catch((err) => console.log(err));
  };

  const getMealItemList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_TYPE_LIST_GET_API}`
      )
      .then((res) => {
        // console.log(res?.data?.meal_types);
        setFoodMealTypeList(res?.data?.meal_types);
      })
      .catch((err) => console.log(err));
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${convertData.subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getfoodItemList();
    getMealItemList();
    getMealPackage();
  }, []);

  const foodItems = transformArray(
    foodItemList,
    "food_item_id",
    "food_item_name"
  );

  const foodMealType = transformArray(
    foodMealTypeList,
    "meal_type_id",
    "meal_type_name"
  );

  function transformPayload(payload) {
    const newPayload = {
      meal_type_id: payload.meal_type_id.value,
      food_items_ids: payload.food_items_ids.map((item) => item.value),
      meal_package_unit_price: parseInt(payload.meal_package_unit_price),
    };

    return newPayload;
  }

  const handleSubmitFood = () => {
    const newPayload = transformPayload(foodPackage);

    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_FOOD_PACKAGE}`, {
        requestId: "food-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_meal_package: newPayload,
      })
      .then((res) => {
        if (res?.status === 201) {
          toast.success("Food Add");
          getMealPackage();
          setFoodPackage({
            meal_type_id: "",
            food_items_ids: [],
            meal_package_unit_price: "",
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleEdit = (food) => {
    const editFood = convertObject(food);
    setFoodPackage(editFood);
  };
  console.log("foodPackage", foodPackage);
  return (
    <div>
      <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
      </div>
      <h2 className="text-[18px] text-center">Food Add</h2>
      <div className="flex justify-start items-start gap-x-[170px]">
        <div className="w-full">
          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Meal Type : </p>
            </div>
            <div className="rounded">
              <Select
                // defaultValue={foodItemOption || ""}
                value={foodPackage?.meal_type_id || ""}
                onChange={(data) =>
                  setFoodPackage((prev) => ({
                    ...prev,
                    meal_type_id: data,
                  }))
                }
                isClearable
                components={animatedComponents}
                options={foodMealType}
                placeholder={"Select Options: "}
                styles={colorStyles}
              />
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Food Items: </p>
            </div>
            <div className="rounded">
              <Select
                value={foodPackage?.food_items_ids}
                onChange={(data) => {
                  setFoodPackage((prev) => ({
                    ...prev,
                    food_items_ids: data,
                  }));
                }}
                components={animatedComponents}
                options={foodItems}
                placeholder={"Select Food: "}
                styles={colorStyles}
                isMulti
              />
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Meal Unit per Price: </p>
            </div>

            <div className="rounded">
              <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                placeholder="Meal Price:"
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;

                  if (!isNaN(inputVal)) {
                    setFoodPackage((prev) => ({
                      ...prev,
                      meal_package_unit_price: inputVal,
                    }));
                  }
                }}
                value={foodPackage?.meal_package_unit_price}
              />
            </div>
          </div>

          <button
            disabled={
              foodPackage?.food_items_ids?.length < 1 &&
              foodPackage?.meal_package_unit_price !== Object &&
              foodPackage?.meal_type_id !== Object
            }
            onClick={() => handleSubmitFood()}
            className="disabled:bg-bg-semiblack h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
          >
            Submit
          </button>
        </div>
        {/* view */}
        <div className="w-full flex flex-col gap-y-[10px]">
          {foodPackageList?.map((food, i) => (
            <div
              onClick={() => handleEdit(food)}
              key={i}
              className="bg-bg-white rounded-[4px] p-[6px] flex flex-col gap-y-[6px]"
            >
              <p className="text-bg-white rounded-[2px] text-center text-[18px] bg-btn-secondary">
                {food?.meal_type_name}
              </p>
              <div className="flex justify-start gap-x-[3px]">
                {food?.food_items?.map((itm, ind) => (
                  <p key={ind}>{itm?.food_item_name},</p>
                ))}
              </div>
              <p>Per Meal Price : {food?.per_meal_package_price} ৳</p>
            </div>
          ))}

          {foodPackageList?.length > 0 && (
            <button
              onClick={() => navigate(-1)}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
            >
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
