import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

import { animatedComponents, colorStyles } from "../Utilities/SelectAnimation";

const MarchantTourSubscribeCard = ({
  tour,
  number_of_daysArray,
  tourSubscribe,
  setTourSubscribe,
}) => {
  const [activites, setActivites] = useState({
    activity_day_number: 0,
    activity_end_time: "",
    activity_id: 0,
    activity_start_time: "",
  });

  const handleActivites = (id) => {
    setTourSubscribe((prev) => ({
      ...prev,
      tour_activities: [
        ...prev.tour_activities,
        {
          activity_day_number: activites.activity_day_number,
          activity_end_time: activites.activity_end_time,
          activity_id: id,
          activity_start_time: activites.activity_start_time,
        },
      ],
    }));
    toast.success("Successfully Added!");

    // setActivites({
    //   activity_day_number: 0,
    //   activity_end_time: "",
    //   activity_id: 1,
    //   activity_start_time: "",
    // });
  };

  return (
    <div className="bg-bg-white flex flex-row shadow-customShadow rounded-[4px] h-[230px]">
      <div className="w-[270px] h-full">
        <img
          className="h-full w-full object-cover rounded-[4px]"
          src={tour?.tour_activity_images[0]?.activity_image_url}
          alt=""
        />
      </div>
      <div className="p-[6px] px-[12px] w-full flex flex-col relative">
        <p className="text-btn-secondary text-[18px] ">
          {" "}
          {tour?.tour_activity_name}
        </p>
        <div>
          <div>
            <p className="text-txt-primary text-[12px]">Number of Days: </p>
          </div>
          <div className="rounded">
            <Select
              value={activites?.activity_day_number}
              onChange={(activity_day_number) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_day_number: activity_day_number,
                }))
              }
              components={animatedComponents}
              options={number_of_daysArray}
              placeholder={"No of Day: "}
              styles={colorStyles}
            />
          </div>
        </div>

        <div className="flex gap-x-[16px] mt-[4px]">
          <div className="w-full">
            <p className="text-[12px]">Activity Start Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_start_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_start_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>
          <div className="w-full">
            <p className="text-[12px]">Activity End Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_end_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_end_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>
        </div>

        <div className="w-[140px] ml-auto mt-[8px] absolute bottom-[6px] right-[12px]">
          <button
            disabled={tourSubscribe.tour_activities
              .map((activity) => activity.activity_id)
              .includes(tour?.tour_activity_id)}
            onClick={() =>
              activites.activity_day_number &&
              activites.activity_end_time &&
              activites.activity_start_time &&
              handleActivites(tour?.tour_activity_id)
            }
            className={` ${
              activites.activity_day_number &&
              activites.activity_end_time &&
              activites.activity_start_time
                ? "bg-btn-primary hover:bg-btn-secondary hover:border-btn-secondary"
                : "bg-bg-semiblack"
            }  py-[3px] disabled:bg-bg-semiblack text-bg-white w-full px-[20px]  border-btn-primary rounded-md shadow-md transition-all duration-500  hover:text-bg-white hover:shadow-lg`}
          >
            {tourSubscribe.tour_activities
              .map((activity) => activity.activity_id)
              .includes(tour?.tour_activity_id)
              ? "Added"
              : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarchantTourSubscribeCard;
