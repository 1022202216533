import React, { useEffect, useState } from "react";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
} from "../../Utilities/SelectAnimation";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { convertStringToObject } from "../ParentPackageSelected";

export default function SpotFeeV2() {
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const navigate = useNavigate();
  const [getTours, setGetTours] = useState();
  const [spotEntry, setSpotEntry] = useState([]);
  const [spotEntity, setSpotEntity] = useState({
    activity_id: "",
    remark: "FOR_ADULT",
    spot_entry_price_per_person: 0,
  });
  const activityVariable = getTours?.tour_itinerary?.map((activity) => ({
    label: activity.tour_activity_name,
    id: activity.tour_activity_id,
  }));
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  function transformPayload(payload) {
    const newPayload = {
      activity_id: payload.activity_id.id,
      remark: payload.remark,

      spot_entry_price_per_person: parseInt(
        payload.spot_entry_price_per_person
      ),
    };

    return newPayload;
  }

  const handleSubmitSpotFee = () => {
    const newPayload = transformPayload(spotEntity);

    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_SPOTFEE_PACKAGE}`, {
        requestId: "spotFee-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_spot_entry_package: newPayload,
      })
      .then((res) => {
        toast.success("Spot fee Add");
        getSpotEntriy();
        setSpotEntity({
          activity_id: "",
          remark: "FOR_ADULT",
          spot_entry_price_per_person: 0,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSpotEntriy = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setSpotEntry(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getSpotEntriy();
  }, []);

  const transformObject = (originalObject) => {
    const b = {
      activity_id: {
        label: originalObject.activity.activity_name,
        value: originalObject.activity.activity_id,
      },
      remark: originalObject.remark,
      spot_entry_price_per_person:
        originalObject.spot_entry_price_per_person.toString(),
    };

    return b;
  };

  const handleEditSpot = (spot) => {
    const editSpot = transformObject(spot);
    setSpotEntity(editSpot);
  };

  console.log("spotEntry", spotEntity);
  return (
    <div>
      <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Spot Add</h2>
      </div>
      <div>
        <div className="flex justify-start items-start gap-x-[170px]">
          <div className=" py-[8px] px-[12px] w-full">
            <div className="w-full flex gap-x-[20px] items-center flex-col mb-[14px]">
              <div className="w-full ">
                <div>
                  <p className="text-txt-primary text-[12px]">
                    Activity Name:{" "}
                  </p>
                </div>
                <div className="rounded">
                  <Select
                    value={spotEntity?.activity_id}
                    onChange={(activity) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        activity_id: activity,
                      }))
                    }
                    components={animatedComponents}
                    options={activityVariable}
                    placeholder={"Activity Name "}
                    styles={colorStyles}
                  />
                </div>
              </div>
              <div className="w-full mt-[10px]">
                <p className="text-[12px]">Spot fee :</p>
                <div>
                  <input
                    type="text"
                    className="border border-btn-secondary w-full h-full py-[4px] px-[12px] rounded-[4px]"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    value={spotEntity.spot_entry_price_per_person}
                    placeholder="entry fee"
                  />
                </div>
              </div>
              <div className="w-full mt-[10px]">
                <p className="text-[12px]">Remark :</p>
                <div>
                  <input
                    type="text"
                    className="border border-btn-secondary w-full h-full py-[4px] px-[12px] rounded-[4px]"
                    onChange={(e) =>
                      setSpotEntity((prev) => ({
                        ...prev,
                        spot_entry_price_per_person: Number(e.target.value),
                      }))
                    }
                    //   value={spotEntity.spot_entry_price_per_person}
                    placeholder="Remark"
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => handleSubmitSpotFee()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button>
          </div>
          {/* view */}
          <div className="px-[12px] flex flex-col justify-start gap-y-[12px] w-full">
            {spotEntry?.map((spot, index) => (
              <div
                key={index}
                className="shadow-customShadow py-[10px] px-[20px] rounded-[4px] relative"
              >
                <p className="text-[22px]">
                  <span className="text-[16px]"> Spot :</span>{" "}
                  {spot?.activity?.activity_name}
                </p>
                <p>Location: {spot?.activity?.activity_short_location}</p>
                <p>Fee : {spot?.spot_entry_price_per_person}</p>
                <span
                  onClick={() => handleEditSpot(spot)}
                  className="border cursor-pointer w-[20px] h-[20px] border-btn-orange rounded-full absolute right-[-10px] top-[-4px]"
                >
                  <span className="flex justify-center items-center h-full">
                    X
                  </span>
                </span>
              </div>
            ))}

            {spotEntry?.length > 0 && (
              <button
                onClick={() => navigate(-1)}
                className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
