import React from "react";

import { useNavigate } from "react-router";
import { merchantTourRoute } from "../../contants/Constants";

const TourSingleCard = ({ tour }) => {
  const navigate = useNavigate();
  const {
    tour_description,
    tour_id,
    tour_itinerary,
    tour_name,
    tour_specialities,
    tour_thumb_image_url,
    tour_title,
    is_subscribed_by_merchant,
  } = tour;

  console.log("is_subscribed_by_merchant", is_subscribed_by_merchant);

  return (
    <div className="py-[10px] px-[8px]">
      {is_subscribed_by_merchant !== true && (
        <div className="w-[279px] bg-[#F5F9FA] rounded-[12px] shadow hover:shadow-lg mx-auto ">
          <div className="">
            <img
              className="rounded-t-[12px] w-[279px] h-[205px]"
              src={tour_thumb_image_url}
              alt=""
            />
          </div>
          <div className="h-[130px] ">
            <div className="text-[#142B33] flex flex-col justify-between gap-4">
              <div className="px-[12px]">
                <div className="mt-[8px]">
                  <p className="text-[18px]">{tour_name}</p>
                </div>
              </div>
              <div className="flex flex-row justify-between items-end px-[12px] text-[14px]">
                <button
                  onClick={() =>
                    navigate(`${merchantTourRoute.MY_TOUR}${tour_id}`)
                  }
                  className="bg-btn-primary text-bg-white px-[12px] py-[8px] rounded transition-all duration-500 shadow-md hover:bg-btn-secondary"
                >
                  Subscribe
                </button>
                <button
                  onClick={() =>
                    navigate(`${merchantTourRoute.MY_TOUR}${tour_id}`)
                  }
                  className="bg-[#2563eb] text-bg-white px-[12px] py-[8px] rounded transition-all duration-500 shadow-md hover:bg-[#3b82f6]"
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourSingleCard;
