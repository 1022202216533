import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";
export default function Accommondation() {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [accomondation, setAccommondation] = useState({
    accommodation_id: "",
    room_category_id: "",
    room_type_id: "",
    per_night_room_price: "",
    suitable_for_persons: "",
    is_shareable: true,
    number_of_beds: "",
    bed_configuration: "",
  });
  const [roomAllList, setRoomAllList] = useState([]);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [roomCategoryList, setRoomCategoryList] = useState([]);
  const accomodationNameArray = transformArray(
    roomAllList,
    "tour_accommodation_id",
    "tour_accommodation_name"
  );

  const accomodationRoomTypeArray = transformArray(
    roomTypeList,
    "tour_room_type_id",
    "tour_room_type_name"
  );
  const accomodationCategoryArray = transformArray(
    roomCategoryList,
    "tour_room_category_id",
    "tour_room_category_name"
  );

  const is_shareableArary = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const getAllPackageList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_GET_ALL_PAGINATED_API}requestId=100`
      )
      .then((res) => {
        // console.log(res?.data?.tour_accommodations);
        setRoomAllList(res?.data?.tour_accommodations);
      })
      .catch((err) => console.log(err));
  };
  const getRoomTypeList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_ROOM_TYPE_GET_PAGINATED_API}requestId=100`
      )
      .then((res) => {
        // console.log(res?.data?.tour_room_types);
        setRoomTypeList(res?.data?.tour_room_types);
      })
      .catch((err) => console.log(err));
  };
  console.log("accomondation", accomondation);
  const getRoomCategoryList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_PACKAGE_ROOM_CATEGORY_GET_PAGINATED_API}requestId=100`
      )
      .then((res) => {
        // console.log(res?.data?.tour_room_categories);
        setRoomCategoryList(res?.data?.tour_room_categories);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllPackageList();
    getRoomTypeList();
    getRoomCategoryList();
    getAccommodation();
  }, []);
  const [accommondationPackageList, setAccommondationPackageList] = useState();

  const getAccommodation = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_GET_API}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        // console.log(res?.data?.tour_room_categories);

        setAccommondationPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const transformPayload = (payload) => {
    const newPayload = {
      ...payload,
      accommodation_id: payload.accommodation_id.value,
      room_category_id: payload.room_category_id.value,
      room_type_id: payload.room_type_id.value,
      per_night_room_price: parseFloat(payload.per_night_room_price),
      suitable_for_persons: parseInt(payload.suitable_for_persons, 10),
      is_shareable: payload.is_shareable.value,
      number_of_beds: parseInt(payload.number_of_beds, 10),
    };

    return newPayload;
  };

  const handleSubmitAccommondation = () => {
    const convertedPayload = transformPayload(accomondation);

    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_ACCOMMONDATION_PACKAGE}`, {
        requestId: "accomondation-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_package_accommodation_package: convertedPayload,
      })
      .then((res) => {
        toast.success("Accommondation added!");
        getAccommodation();
        setAccommondation({
          accommodation_id: "",
          room_category_id: "",
          room_type_id: "",
          per_night_room_price: "",
          suitable_for_persons: "",
          is_shareable: true,
          number_of_beds: "",
          bed_configuration: "",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const transformObject = (originalObject) => {
    const b = {
      accommodation_id: {
        label: originalObject.tour_package_accommodation_name,
        value: originalObject.tour_package_accommodation_id,
      },
      room_category_id: {
        label: originalObject.tour_package_room_category_name,
        value: originalObject.tour_package_room_category_id,
      },
      room_type_id: {
        label: originalObject.tour_package_room_type_name,
        value: originalObject.tour_package_room_type_id,
      },
      per_night_room_price: originalObject.per_night_room_price.toString(),
      suitable_for_persons: originalObject.suitable_for_persons.toString(),
      is_shareable: {
        value: originalObject.is_room_shareable,
        label: originalObject.is_room_shareable ? "Yes" : "No",
      },
      number_of_beds: originalObject.bed_count.toString(),
      bed_configuration: originalObject.bed_configuration,
    };

    return b;
  };

  const handleEditAccommondation = (acc) => {
    const editAcco = transformObject(acc);
    setAccommondation(editAcco);
  };
  return (
    <div>
      <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
      </div>
      <h2
        className="text-[18px] text-txt-primary text-center
      "
      >
        Accommondation Package
      </h2>
      <div className="flex justify-start gap-x-[160px] items-start mt-[16px]">
        <div className="w-full">
          <div className=" mb-[8px]">
            <div>
              <p className="text-btn-secondary">Accomodation Name: </p>
            </div>
            <div className="rounded">
              <Select
                value={accomondation?.accommodation_id || ""}
                onChange={(data) =>
                  setAccommondation((prev) => ({
                    ...prev,
                    accommodation_id: data,
                  }))
                }
                isClearable
                components={animatedComponents}
                options={accomodationNameArray}
                placeholder={"Select Hotel: "}
                styles={colorStyles}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between">
            <div className="mt-[8px] mb-[8px] w-full">
              <div>
                <p className="text-btn-secondary">Room Type(s): </p>
              </div>
              <div className="rounded">
                <Select
                  value={accomondation?.room_type_id}
                  onChange={(data) =>
                    setAccommondation((prev) => ({
                      ...prev,
                      room_type_id: data,
                    }))
                  }
                  components={animatedComponents}
                  options={accomodationRoomTypeArray}
                  placeholder={"Select Type: "}
                  styles={colorStyles}
                />
              </div>
            </div>
          </div>

          <div className="my-[8px]">
            <div>
              <p className="text-btn-secondary">Room Category(ies): </p>
            </div>
            <div className="rounded">
              <Select
                value={accomondation?.room_category_id}
                onChange={(data) =>
                  setAccommondation((prev) => ({
                    ...prev,
                    room_category_id: data,
                  }))
                }
                components={animatedComponents}
                options={accomodationCategoryArray}
                placeholder={"Select Category: "}
                styles={colorStyles}
              />
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Is Shareble: </p>
            </div>
            <div className="rounded">
              <Select
                value={accomondation?.is_shareable}
                onChange={(data) =>
                  setAccommondation((prev) => ({
                    ...prev,
                    is_shareable: data,
                  }))
                }
                components={animatedComponents}
                options={is_shareableArary}
                placeholder={"Select: "}
                styles={colorStyles}
              />
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Number of Beds: </p>
            </div>
          </div>
          <div className="rounded">
            <div className="mt-[10px]">
              <input
                onChange={(e) =>
                  setAccommondation((prev) => ({
                    ...prev,
                    number_of_beds: e.target.value,
                  }))
                }
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                type="text"
                placeholder="number of beds "
                value={accomondation?.number_of_beds}
              />
            </div>
          </div>
          <div className="my-[16px]">
            <p className="text-btn-secondary">Bed Configuration: </p>
            <input
              className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
              placeholder="King/Queen"
              type="text"
              onChange={(e) =>
                setAccommondation((prev) => ({
                  ...prev,
                  bed_configuration: e.target.value,
                }))
              }
              value={accomondation?.bed_configuration}
            />
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Suitable for Persons: </p>
            </div>
            <div className="rounded">
              <div className="mt-[10px]">
                <input
                  onChange={(e) =>
                    setAccommondation((prev) => ({
                      ...prev,
                      suitable_for_persons: e.target.value,
                    }))
                  }
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  type="text"
                  placeholder="Suitable for persons "
                  value={accomondation?.suitable_for_persons}
                />
              </div>
              {/* <Select
                      value={accomodationPackage?.suitable_for_persons}
                      onChange={(data) =>
                        setAccomodationPackage((prev) => ({
                          ...prev,
                          suitable_for_persons: data,
                        }))
                      }
                      components={animatedComponents}
                      options={suitable_for_personsArray}
                      placeholder={"Select persons: "}
                      styles={colorStyles}
                    /> */}
            </div>
          </div>

          <div className="my-[16px]">
            <div>
              <p className="text-btn-secondary">Per Night Price </p>
            </div>
            <div className="rounded">
              <input
                className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                type="number"
                onChange={(e) => {
                  const inputVal = e.target.value;

                  if (!isNaN(inputVal)) {
                    setAccommondation((prev) => ({
                      ...prev,
                      per_night_room_price: inputVal,
                    }));
                  }
                }}
                placeholder="Unit per price "
                value={accomondation?.per_night_room_price}
              />
            </div>
          </div>

          <button
            onClick={() => handleSubmitAccommondation()}
            className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
          >
            Submit
          </button>
        </div>
        <div className="w-full">
          <div className="max-h-[1000px] overflow-y-scroll mb-[20px]">
            {accommondationPackageList?.map((item, index) => (
              <div
                onClick={() => handleEditAccommondation(item)}
                key={index}
                className="bg-bg-white md:my-0 md:mb-[16px] my-[16px] p-[16px] rounded overflow-clip relative"
              >
                <p className="px-[8px] py-[4px] bg-[#e2e8f0] text-btn-secondary xl:text-[16px] text-[16px] text-center mb-[8px] rounded">
                  {item?.tour_package_accommodation_name} -{" "}
                  {item?.tour_package_room_category_name}
                </p>

                <div className="xl:text-[14px] text-[12px]">
                  <p className="text-txt-primary">
                    {" "}
                    <span className="text-bg-semiblack">Room Type:</span>{" "}
                    {item?.tour_package_room_type_name}{" "}
                  </p>
                  <p className="text-txt-primary">
                    <span className="text-bg-semiblack">
                      Bed Configuration:{" "}
                    </span>
                    {item?.bed_count} {item?.bed_configuration} suitable for{" "}
                    {item?.suitable_for_persons} persons{" "}
                  </p>
                  <div className="flex justify-between">
                    <p className="text-txt-primary">
                      <span className="text-bg-semiblack">Shareable: </span>
                      {item?.is_room_shareable ? "Yes" : "No"}
                    </p>
                  </div>
                  <p className="text-txt-primary">
                    <span className="text-bg-semiblack">
                      Price per quantity:{" "}
                    </span>
                    {item?.per_night_room_price}৳
                  </p>
                </div>

                {/* <span
                        onClick={() =>
                          handleRemoveAccommondation(index, item?.night_number)
                        }
                        className="absolute right-[2px] cursor-pointer top-[2px]"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[25px] h-[25px] text-btn-pink"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span> */}
                <span
                  //   onClick={() => handleEditAccommondation(item?.night_number)}
                  className="absolute right-[4px] cursor-pointer top-[2px] border-[1.5px] rounded-full border-btn-primary p-[4px]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-[15px] h-[15px] text-btn-primary"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                  </svg>
                </span>
              </div>
            ))}
            {accommondationPackageList?.length > 0 && (
              <button
                onClick={() => navigate(-1)}
                className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
              >
                Save
              </button>
            )}
          </div>
        </div>
        {/* view  */}
      </div>
    </div>
  );
}
