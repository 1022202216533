import React, { useEffect, useState } from "react";
import SteperRaper from "../../../excursionMarchant/Steper/Steper";
import { useParams } from "react-router";
import Calender from "../../../components/Calender";
import { authAxios } from "../../../utils/axiosWrapper";
import { MERCHANT_TOUR_ENDPOINT } from "../../../contants/Endpoints";
import FoodPackage from "./PackageCreate/FoodPackage";
import AccommodationPackage from "./PackageCreate/AccommodationPackage";
import TransferPackage from "./PackageCreate/TransferPackage";
import TransportationPackage from "./PackageCreate/TransportationPackage";
import GuidePackage from "./PackageCreate/GuidePackage";
import Select from "react-select";
import SpotFeePackage from "./PackageCreate/SpotFeePackage";
import {
  animatedComponents,
  colorStyles,
  transformArray,
} from "../../Utilities/SelectAnimation";
import { generateDates } from "./AbibilitySetV2";
import { convertStringToObject } from "../ParentPackageSelected";

const formatDate = (inputDate) => {
  const dateObject = new Date(inputDate);

  // Extracting day, month, and year components
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = dateObject.getFullYear();

  // Forming the formatted date string
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export default function AvailabilityPackageCreate({
  setRequestToPayload,
  requestToPayload,
  setPageChange,
  saveDataMultipleToTourPackage,
  getMultipleDate,
  setGetMultipleDate,
}) {
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  const [activeStep, setActiveStep] = useState(0);
  const [rejectionArray, setRejectionArray] = useState([]);
  const [startDate, setStartDate] = useState();

  const [packageType, setPackageType] = useState();
  const getPackageType = () => {
    authAxios
      .get(`${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_PACKAGE_TYPE}`)
      .then((res) => {
        setPackageType(res?.data?.tour_package_types);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getPackageType();
  }, []);
  console.log("getMultipleDate", getMultipleDate);
  const startingDate = startDate && formatDate(startDate);
  useEffect(() => {
    // setRequestToPayload((prev) => ({
    //   ...prev,
    //   tour_start_date: formatDate(startDate),
    // }));
    if (startDate !== undefined && startDate !== null) {
      // setGetMultipleDate((prev) => [...prev, startingDate]);

      // setGetMultipleDate((prev) => {
      //   if (prev.length > 0) {
      //     const lastDateInArray = prev[prev.length - 1];

      //     const startDateDifference =
      //       new Date(startingDate) - new Date(lastDateInArray);
      //     const fourDaysInMilliseconds =
      //       Number(convertData.number_of_days) * 24 * 60 * 60 * 1000;

      //     if (startDateDifference >= fourDaysInMilliseconds) {
      //       return [...prev, startingDate];
      //     }
      //   } else {
      //     return [startingDate];
      //   }

      //   return prev;
      // });
      setGetMultipleDate((prev) => {
        // Check if the startingDate is already in the array
        if (!prev.includes(startingDate)) {
          // If the date is not a duplicate  then add to the array
          return [...prev, startingDate];
        }

        // If the date is a duplicate, then return the previous date
        return prev;
      });
    }
  }, [startingDate]);

  const packageArray = transformArray(
    packageType,
    "tour_package_type_id",
    "tour_package_type_name"
  );

  const handleDeleteDate = (dates) => {
    const updateDates = getMultipleDate?.filter(
      (allDates) => allDates !== dates
    );
    setGetMultipleDate(updateDates);
  };

  return (
    <div>
      <SteperRaper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        steps={7}
      />
      {activeStep === 0 ? (
        <div className="flex justify-start items-start gap-x-[40px] mt-[30px] h-full">
          <div className="w-full  bg-bg-white p-[6px] rounded-[4px] flex flex-col gap-y-[20px] h-full">
            <div className="flex justify-between items-center  gap-x-[30px]">
              <p className="text-[16px] whitespace-nowrap">Select Days :</p>
              <div className="w-full">
                <Calender
                  setSelectedDate={setStartDate}
                  selectedData={startDate ? new Date(startDate) : null}
                  placeholder="Tour start date"
                  isReadOnly={false}
                  rejectionArray={rejectionArray}
                  setRejectionArray={setRejectionArray}
                  name="startDate"
                  isShow={false}
                />
              </div>
            </div>

            <div className="flex justify-start items-center gap-x-[20px]">
              <p className="whitespace-nowrap">Select Package Type: </p>
              <div className="rounded w-full">
                <Select
                  value={requestToPayload?.tour_package_type_id}
                  onChange={(data) =>
                    setRequestToPayload((prev) => ({
                      ...prev,
                      tour_package_type_id: data,
                    }))
                  }
                  // handleOnChange(data)

                  components={animatedComponents}
                  options={packageArray}
                  placeholder={"Select Package: "}
                  styles={colorStyles}
                  isClearable
                />
              </div>
              {/* <div className="flex gap-x-[6px] ">
                {packageType?.map((typePackage, ind) => (
                  <div
                    onClick={() =>
                      setRequestToPayload((prev) => ({
                        ...prev,
                        tour_package_type_id: typePackage,
                      }))
                    }
                    className="bg-btn-secondary px-[6px] py-[3px] rounded-[4px] text-bg-white"
                  >
                    {typePackage?.tour_package_type_name}
                  </div>
                ))}
              </div> */}
            </div>
            <div className="flex justify-start gap-x-[10px] items-center">
              <p className="whitespace-nowrap">Total Seat : </p>
              <input
                onChange={(e) =>
                  setRequestToPayload((prev) => ({
                    ...prev,
                    total_seats: e.target.value,
                  }))
                }
                type="text"
                className="border border-txt-hint w-full px-[12px] py-[4px] rounded-[4px]"
                placeholder="Total Seat"
              />
            </div>

            <div className="flex justify-start gap-x-[10px] items-center">
              <p className="whitespace-nowrap">Bookable Seat : </p>
              <input
                onChange={(e) =>
                  setRequestToPayload((prev) => ({
                    ...prev,
                    bookable_seats: e.target.value,
                  }))
                }
                type="text"
                className="border border-txt-hint w-full px-[12px] py-[4px] rounded-[4px]"
                placeholder="Bookable Seat"
              />
            </div>
          </div>
          <div className="w-full h-full flex flex-col gap-[16px]">
            <div className="">
              <div>
                <span> Available Date Slot:</span>
                <div className="flex flex-col gap-[8px] max-h-[300px] overflow-y-scroll">
                  {getMultipleDate?.map((date, i) => (
                    <div key={i}>
                      <p className="flex justify-start gap-x-[4px]">
                        Slot {i + 1}{" "}
                        {generateDates(date, convertData.number_of_days)?.map(
                          (d, i) => (
                            <span
                              key={i}
                              className="bg-btn-secondary text-bg-white py-[3px] rounded-[2px] px-[4px] mx-[6px]"
                            >
                              {d}
                            </span>
                          )
                        )}
                        <button
                          onClick={() => handleDeleteDate(date)}
                          className="cursor-pointer text-[18px]"
                        >
                          x
                        </button>
                      </p>
                    </div>
                  ))}
                </div>
                {/* {dateNew?.map((singleDate, index) => (
                  <span
                    className="bg-btn-secondary text-bg-white py-[3px] px-[6px] mx-[3px] rounded-[2px]"
                    key={index}
                  >
                    {singleDate}
                  </span>
                ))} */}
              </div>
            </div>
            <p>
              Package:{" "}
              <span className="bg-btn-secondary text-bg-white py-[3px] px-[6px] mx-[3px] rounded-[2px]">
                {" "}
                {requestToPayload?.tour_package_type_id?.label}
              </span>{" "}
            </p>
            <p>Total Seat : {requestToPayload?.total_seats}</p>
            <p>Bookable Seat : {requestToPayload?.bookable_seats}</p>
            <div
              className="flex justify-end
           items-end h-full"
            >
              <button
                onClick={() => setActiveStep(1)}
                className="bg-btn-secondary w-[150px] h-[39px]  flex justify-center items-center text-bg-white rounded-[4px]"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      ) : activeStep === 1 ? (
        <FoodPackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setActiveStep={setActiveStep}
        />
      ) : activeStep === 2 ? (
        <AccommodationPackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setActiveStep={setActiveStep}
        />
      ) : activeStep === 3 ? (
        <TransferPackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setActiveStep={setActiveStep}
        />
      ) : activeStep === 4 ? (
        <TransportationPackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setActiveStep={setActiveStep}
        />
      ) : activeStep === 5 ? (
        <GuidePackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setActiveStep={setActiveStep}
        />
      ) : activeStep === 6 ? (
        <SpotFeePackage
          setRequestToPayload={setRequestToPayload}
          requestToPayload={requestToPayload}
          setPageChange={setPageChange}
          saveDataMultipleToTourPackage={saveDataMultipleToTourPackage}
        />
      ) : null}
    </div>
  );
}
