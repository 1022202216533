import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { merchantRoute, merchantTourRoute } from "../../contants/Constants";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../contants/Endpoints";
import SliderCart from "../../pages/admin/NewTourCreateToll/SliderCart";
import { authAxios } from "../../utils/axiosWrapper";
import { convertStringToObject } from "./ParentPackageSelected";

export default function TourPackageSeleted() {
  const navigate = useNavigate();
  const [getTours, setGetTours] = useState();
  const [foodPackageList, setFoodPackageList] = useState([]);
  const [accommondationPackageList, setAccommondationPackageList] = useState(
    []
  );
  const [transferPackageList, setTransferPackageList] = useState([]);
  const [transportationPackages, setTransportationPackages] = useState([]);
  const [guidePackageList, setGuidePackageList] = useState([]);
  const [spotEntry, setSpotEntry] = useState([]);
  const [availablePackage, setAvailablePackage] = useState([]);
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);
  console.log("availablePackage", availablePackage);
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  function mergeData(a, b) {
    const c = {
      tour_package_types: b?.tour_package_types?.map((item) => {
        const matchingPackage = a?.tour_packages.find(
          (packages) =>
            packages.tour_package_type_id === item.tour_package_type_id
        );

        if (matchingPackage) {
          return {
            tour_package_type_id: matchingPackage.tour_package_type_id,
            tour_package_id: matchingPackage.tour_package_id,
            tour_package_type_name: matchingPackage.tour_package_type_name,
            tour_package_type_description:
              matchingPackage.tour_package_description,
            tour_package_total_options:
              matchingPackage.tour_package_total_options,
            tour_package_default_option_price_per_person:
              matchingPackage.tour_package_default_option_price_per_person,
            priceSet: true,
          };
        } else {
          return item;
        }
      }),
    };

    return c;
  }

  const [isloading, setIsLoading] = useState(false);

  const getSpotEntriy = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SPOT_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setSpotEntry(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setGuidePackageList(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  const getTransportationPackage = () => {
    // merchant/tour/transportation/package/get/all/by/
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSPORTATION_ALL_PACKAGE}${convertData?.subscribeId}?requestId=10`
      )
      .then((res) => {
        // console.log(res?.data?.transportation_brands);
        setTransportationPackages(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getMealPackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_FOOD_PACKAGE_MEAL_PACKAGE_GET_API}${convertData?.subscribeId}?requestId=meal_package`
      )
      .then((res) => {
        setFoodPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAccommodation = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_ACCOMMODATION_GET_API}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        // console.log(res?.data?.tour_room_categories);
        console.log("res", res?.data);
        setAccommondationPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getTransferPackage = () => {
    // merchant/tour/transfer/package/get/all/by/7?requestId=123
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_TRANSFER_ALL_PACKAGE}${convertData?.subscribeId}?requestId=100`
      )
      .then((res) => {
        setTransferPackageList(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const getAvailibilityPackageTableData = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MARCHANT_GET_ALL_AVAILABLE_PACKAGE_DATA}${convertData.subscribeId}?requestId=package_data`
      )
      .then((res) => {
        setAvailablePackage(res?.data?.datePackageMap);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getMealPackage();
    getAccommodation();
    getTransferPackage();
    getTransportationPackage();
    getGuidePackage();
    getSpotEntriy();
    getAvailibilityPackageTableData();
  }, []);
  const itemList = [
    { label: "Food", route: "add-food" },
    { label: "Accommondation", route: "add-accommondation" },
    { label: "Transfer", route: "add-transfer" },
    { label: "Transportation", route: "add-transportaion" },
    { label: "Guide", route: "add-guide" },
    { label: "Spot Fee", route: "add-spot-fee" },
  ];
  console.log("getTours", getTours);
  const handleAbibilityPage = () => {
    navigate(
      `${merchantTourRoute.MARCHANT_SET_ABIBILITY_V2}tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}&number_of_days=${getTours.number_of_days}&number_of_nights=${getTours.number_of_nights}`
    );
  };

  return (
    <div className="max-w-[1100px] mx-auto">
      <div className="flex justify-start items-start gap-x-[20px]">
        <div className="w-full">
          <div className="w-[355px] shadow-customShadow rounded-t-[8px] overflow-hidden">
            <div className=" mx-auto w-full h-[180px] relative">
              <img
                src={getTours?.tour_thumb_image_url}
                alt="ghuddy"
                loading="lazy"
                className="object-cover w-full h-full"
              />
              <p className="text-bg-white z-[10] absolute bottom-[10px] left-[10px] text-[16px] ">
                #{getTours?.tour_title}
              </p>
            </div>
            <div className="mt-[14px] px-[6px]">
              <h2 className="text-[16px]">{getTours?.tour_name}</h2>
              <p className="text-[12px]">
                {getTours?.tour_description?.slice(0, 150)}
              </p>

              {/* tour iteniry */}
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Itinerary</p>

                <SliderCart
                  cart={getTours?.tour_itinerary}
                  containerWidth={"w-[343px]"}
                  cartWidth={130}
                  cartViewNumber={2.5}
                >
                  {getTours?.tour_itinerary?.map((itinerary, index) => (
                    <div key={index} className="max-w-[130px] min-w-[130px]">
                      <p
                        title={itinerary?.tour_activity_name}
                        className="text-[14px]  truncate"
                      >
                        {itinerary?.tour_activity_name}
                      </p>
                      <div className="w-[130px] h-[80px] rounded-[8px] overflow-hidden">
                        <img
                          src={
                            itinerary?.tour_activity_images[0]
                              ?.activity_image_url
                          }
                          alt="ghuddy"
                          loading="lazy"
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                  ))}
                </SliderCart>
              </div>

              {/* tour speciality */}
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Speciality</p>

                <SliderCart
                  cart={getTours?.tour_specialities}
                  containerWidth={"w-[343px]"}
                  cartWidth={230}
                  cartViewNumber={1.5}
                >
                  {getTours?.tour_specialities?.map((speciality, index) => (
                    <div
                      key={index}
                      className="min-w-[230px] max-w-[230px] p-[8px] border-btn-secondary border-[0.0.001px] shadow-customShadow my-[6px]"
                    >
                      <p className="text-[13px] font-extrabold text-btn-secondary flex justify-start gap-x-[4px] items-center ">
                        <span className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]">
                          <img
                            className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]"
                            src={speciality?.tour_speciality_icon_url}
                            alt="Ghuddy"
                          />
                        </span>
                        {speciality?.tour_speciality_title}
                      </p>
                      <p className="text-[12px] mt-[3px]">
                        {speciality?.tour_speciality_description?.slice(0, 80)}
                      </p>
                    </div>
                  ))}
                </SliderCart>
              </div>
            </div>
          </div>
        </div>
        {/* old view */}
        {/* <div className="flex flex-col gap-y-[12px] w-full">
          {updateMatchPackage &&
            updateMatchPackage?.tour_package_types?.map((item, index) => (
              <div
                key={index}
                className="shadow-customShadow p-[10px] rounded-[4px] flex flex-col gap-y-[10px]"
              >
                <p className="text-[18px] text-btn-secondary">
                  {item?.tour_package_type_name}
                </p>
                <p className="text-[14px]">
                  {item?.tour_package_type_description}
                </p>

                {item?.priceSet ? (
                  <>
                    <p>
                      {item?.tour_package_total_options} Please add your price{" "}
                    </p>
                    <button
                      onClick={() =>
                        navigate(
                          `${merchantTourRoute.ABIBILITY}tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}&packageId=${item.tour_package_id}`
                        )
                      }
                      className="bg-btn-secondary text-bg-white px-[50px] py-[3px] rounded-[4px] mx-auto flex justify-center mt-[20px] "
                    >
                      Set Price
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() =>
                      navigate(
                        `${merchantTourRoute.CREATE_PACKAGE_INIT}tourName=${getTours?.tour_name}&packageName=${item?.tour_package_type_name}&packageId=${item.tour_package_type_id}&${id}`
                      )
                    }
                    className="bg-btn-secondary rounded-[4px] text-bg-white py-[4px]"
                  >
                    Create Option
                  </button>
                )}
              </div>
            ))}
        </div> */}

        {/* new addition */}
        <div className="flex flex-col gap-y-[20px] w-full">
          {itemList?.map((item, index) => (
            <div
              onClick={() =>
                navigate(
                  `${merchantTourRoute.PACKAGE_CREATE}/${item.route}/tour_id=${convertData.tour_id}&subscribeId=${convertData.subscribeId}`
                )
              }
              key={index}
              className="bg-bg-white hover:border-b hover:border-btn-secondary pl-[30px] pr-[10px] h-[50px] cursor-pointer flex justify-between items-center"
            >
              <p className="text-[18px] text-txt-primary flex justify-start items-center gap-x-[10px]">
                {item?.label}{" "}
                <span>
                  {item?.label === "Food"
                    ? "x" + foodPackageList?.length
                    : item?.label === "Accommondation"
                    ? "x" + accommondationPackageList?.length
                    : item?.label === "Transfer"
                    ? "x" + transferPackageList?.length
                    : item?.label === "Transportation"
                    ? "x" + transportationPackages?.length
                    : item?.label === "Guide"
                    ? "x" + guidePackageList?.length
                    : item?.label === "Spot Fee"
                    ? "x" + spotEntry?.length
                    : null}
                </span>
              </p>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </span>
            </div>
          ))}

          <div>
            <div className="flex flex-col bg-bg-white">
              {Object.keys(availablePackage)?.map((key) => (
                <div className="flex flex-row gap-y-[30px] w-full border border-btn-secondary">
                  <p className="border-r border-btn-secondary flex justify-center items-center px-[30px] whitespace-nowrap">
                    {key}
                  </p>

                  <div className="flex flex-col w-full">
                    {availablePackage[key]?.map((valueData, index) => (
                      <div
                        key={index}
                        className={`${
                          index % 2 !== 0
                            ? "border-b border-btn-secondary"
                            : "border-none"
                        }  p-[8px] flex justify-between items-center`}
                      >
                        <p>{valueData?.tour_package_type_name}</p>

                        <div>
                          {valueData?.available_tour_package_id !== null ? (
                            <button
                              onClick={() =>
                                navigate(
                                  `${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}packageId=${valueData?.available_tour_package_id}&date=${key}`
                                )
                              }
                            >
                              view
                            </button>
                          ) : (
                            <button onClick={() => handleAbibilityPage()}>
                              +
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={() => handleAbibilityPage()}
            className="w-full bg-btn-secondary  py-[6px] rounded-[4px] text-bg-white"
          >
            Set Availability
          </button>
        </div>
      </div>
    </div>
  );
}
