import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ModalCommon from "../../components/common/ModalCommon";

import { merchantTourRoute } from "../../contants/Constants";

import { toast } from "react-toastify";
import { apiEndPoint, baseUrl } from "../../contants/Endpoints";
import CustomInputWithDropdown from "../../pages/admin/NewTourCreateToll/CustomInputWithDropdown";
import SliderCart from "../../pages/admin/NewTourCreateToll/SliderCart";
import { authAxios } from "../../utils/axiosWrapper";
import MarchantTourSubscribeCard from "./MarchantTourSubscribeCard";

export function convertTo12HourFormat(time24) {
  const [hours, minutes] = time24.split(":");
  const date = new Date(0, 0, 0, hours, minutes);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
}

export function generateArray(endValue) {
  const result = [];
  for (let i = 1; i <= endValue; i++) {
    result.push(i);
  }
  return result;
}
const MarchantTourSubscribe = () => {
  const [getTours, setGetTours] = useState();
  const [modalShown, setToggleModal] = useState(false);

  const navigate = useNavigate();
  const marchantId = localStorage.getItem("id");
  const { id } = useParams();

  const [activites, setActivites] = useState({
    activity_day_number: 0,
    activity_end_time: "",
    activity_id: 0,
    activity_start_time: "",
  });

  // console.log(
  //   "getTours",
  //   getTours?.tour_itinerary[0]?.tour_activity_images[0]?.activity_image_url
  // );

  // console.log("activity_day_number", activity_day_number);

  const [tourSubscribe, setTourSubscribe] = useState({
    merchant_id: Number(marchantId),
    tour_activities: [],
    tour_id: Number(id),
    tour_reporting_time: "",
    tour_starting_place: "",
  });

  const dayArray = generateArray(getTours?.number_of_days);
  const number_of_daysArray = dayArray?.map((day) => ({
    label: day,
    value: day,
  }));

  useEffect(() => {
    authAxios
      .get(
        `${
          baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API
        }${id}?requestId=er34`
      )
      .then((res) => {
        setGetTours(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);

  const activityVariable = getTours?.tour_itinerary?.map((activity) => ({
    label: activity.tour_activity_name,
    id: activity.tour_activity_id,
  }));

  // const handleRemoveActivites = (ind) => {
  //   const newArray = tourSubscribe?.tour_activities?.filter(
  //     (activity, index) => index !== ind
  //   );
  //   setTourSubscribe((prev) => ({
  //     ...prev,
  //     tour_activities: newArray,
  //   }));
  // };
  const subscribeTour = () => {
    const modifiedObject = {
      ...tourSubscribe,
      tour_activities: tourSubscribe.tour_activities.map((activity) => ({
        ...activity,

        activity_day_number: activity.activity_day_number.value,
      })),
    };

    authAxios
      .post(`${baseUrl + apiEndPoint.MARCHENT_POST_SUBSCRIBE_TOUR_API}`, {
        ...modifiedObject,
      })
      .then((res) => {
        if (res?.status === 200) {
          console.log("res", res?.data?.subscribed_tour?.subscribed_tour_id);
          // navigate(
          //   `${merchantTourRoute.CREATE_TOUR}${res?.data?.subscribed_tour?.subscribed_tour_id}`
          // );
          navigate(
            `${merchantTourRoute.TOURONE}tour_id=${id}&subscribeId=${res?.data?.subscribed_tour?.subscribed_tour_id}`
          );
        } else {
          toast.success("Something went wrong");
        }
        console.log(res);
      })
      .catch((err) => console.log(err));

    // navigate(merchantTourRoute.CREATE_TOUR);
  };
  console.log(
    "tourSubscribe",
    tourSubscribe?.tour_starting_place !== undefined &&
      tourSubscribe?.tour_activities?.length === 0
  );
  return (
    <div>
      <div className="max-w-[1100px] mx-auto">
        <div className="grid grid-cols-6 gap-[20px]">
          <div className=" col-span-2 shadow-customShadow rounded-t-[8px] overflow-hidden max-h-[610px] bg-bg-white rounded-[6px] ">
            <div className=" mx-auto w-full h-[180px] relative">
              <img
                src={getTours?.tour_thumb_image_url}
                alt="ghuddy"
                loading="lazy"
                className="object-cover w-full h-full"
              />
              <p className="text-bg-white z-[10] absolute bottom-[10px] left-[10px] text-[16px] ">
                #{getTours?.tour_title}
              </p>
            </div>
            <div className="mt-[14px] px-[6px]">
              <h2 className="text-[16px]">{getTours?.tour_name}</h2>
              <p className="text-[12px]">
                {getTours?.tour_description?.slice(0, 150)}
              </p>

              {/* tour iteniry */}
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Itinerary</p>

                <SliderCart
                  cart={getTours?.tour_itinerary}
                  containerWidth={"w-[343px]"}
                  cartWidth={130}
                  cartViewNumber={2.5}
                >
                  {getTours?.tour_itinerary?.map((itinerary, index) => (
                    <div key={index} className="max-w-[130px] min-w-[130px]">
                      <p
                        title={itinerary?.tour_activity_name}
                        className="text-[14px] truncate"
                      >
                        {itinerary?.tour_activity_name}
                      </p>
                      <div className="w-[130px] h-[80px] rounded-[8px] overflow-hidden">
                        <img
                          src={
                            itinerary?.tour_activity_images[0]
                              ?.activity_image_url
                          }
                          alt="ghuddy"
                          loading="lazy"
                          className="object-cover w-full h-full"
                        />
                      </div>
                    </div>
                  ))}
                </SliderCart>
              </div>

              {/* tour speciality */}
              <div className="mt-[10px]">
                <p className="text-[12px] text-btn-primary">Tour Speciality</p>

                <SliderCart
                  cart={getTours?.tour_specialities}
                  containerWidth={"w-[343px]"}
                  cartWidth={230}
                  cartViewNumber={1.5}
                >
                  {getTours?.tour_specialities?.map((speciality, index) => (
                    <div
                      key={index}
                      className="min-w-[230px] max-w-[230px] p-[8px] border-btn-secondary border-[0.0.001px] shadow-customShadow my-[6px]"
                    >
                      <p className="text-[13px] font-extrabold text-btn-secondary flex justify-start gap-x-[4px] items-center">
                        <span className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]">
                          <img
                            className="min-w-[24px] max-w-[24px] max-h-[24px] min-h-[24px]"
                            src={speciality?.tour_speciality_icon_url}
                            alt="Ghuddy"
                          />
                        </span>
                        {speciality?.tour_speciality_title}
                      </p>
                      <p className="text-[12px] mt-[3px]">
                        {speciality?.tour_speciality_description?.slice(0, 80)}
                      </p>
                    </div>
                  ))}
                </SliderCart>
              </div>
            </div>
          </div>
          <div className="col-span-4 px-[14px] ">
            <div className="max-w-[670px]">
              <div className="w-full mx-auto flex flex-col gap-y-[10px]">
                <div className="w-full">
                  <label className="custom-field one w-full">
                    <input
                      onChange={(e) =>
                        setTourSubscribe((prev) => ({
                          ...prev,
                          tour_starting_place: e.target.value,
                        }))
                      }
                      className="input w-full"
                      type="text"
                      placeholder=" "
                      value={tourSubscribe?.tour_starting_place}
                    />
                    <span className="placeholder">Starting Place :</span>
                  </label>
                </div>

                <div className="w-full">
                  <p className="text-[12px]">Reporting Time : </p>
                  <input
                    onChange={(e) =>
                      setTourSubscribe((prev) => ({
                        ...prev,
                        tour_reporting_time: e.target.value,
                      }))
                    }
                    type="time"
                    id="session-time"
                    name="appt"
                    step="0"
                    value={tourSubscribe?.tour_reporting_time}
                    required
                    style={{
                      padding: "4px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      fontSize: "16px",
                      width: "100%",
                      outline: "none",
                      backgroundColor: "#f5f5f5",
                      color: "#9e9989",
                      fontFamily: "Arial, sans-serif",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      transition:
                        "border-color 0.3s ease, box-shadow 0.3s ease",
                    }}
                  />
                </div>

                {/* <div className="flex flex-col gap-y-[16px] mt-[8px]">
                  {tourSubscribe?.tour_activities?.map((activities, index) => (
                    <div
                      key={index}
                      className=" relative p-[8px] rounded-[6px] border-[0.0.001px] border-btn-secondary shadow-customShadow"
                    >
                      <p className="text-[16px] text-center">
                        {activities?.activity_id?.label}
                      </p>
                      <p className="text-[14px]">
                        {" "}
                        Day: {activities?.activity_day_number}
                      </p>

                      <p className="flex justify-between mt-[4px] text-[14px]">
                        <span className="">
                          Start Time:{" "}
                          {convertTo12HourFormat(
                            activities?.activity_start_time
                          )}
                        </span>
                        <span>
                          End Time:{" "}
                          {convertTo12HourFormat(activities?.activity_end_time)}
                        </span>
                      </p>

                      <span
                        onClick={() => handleRemoveActivites(index)}
                        className="cursor-pointer absolute right-[-10px] top-[-10px] "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-[24px] h-[24px] text-[#EC7063]"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div> */}
                {/* <button
                onClick={() => setToggleModal(true)}
                className="bg-btn-secondary py-[6px] text-bg-white rounded-[4px]"
              >
                Add Activites
              </button> */}
              </div>
              <div className="flex flex-col gap-[12px]  ">
                {getTours?.tour_itinerary &&
                  getTours?.tour_itinerary?.map((tour, index) => (
                    <Fragment key={index}>
                      <div className="mt-[20px]">
                        <MarchantTourSubscribeCard
                          key={index}
                          tour={tour}
                          number_of_daysArray={number_of_daysArray}
                          tourSubscribe={tourSubscribe}
                          setTourSubscribe={setTourSubscribe}
                        ></MarchantTourSubscribeCard>
                      </div>
                      {/* <div  className="bg-bg-white flex flex-row shadow-customShadow rounded-[4px] h-[230px] overflow-hidden">
                      
                      <div className="w-[270px] h-full">
                        <img
                          className="h-full w-full object-cover rounded-t"
                          src={
                            tour?.tour_activity_images[0]?.activity_image_url
                          }
                          alt=""
                        />
                      </div>
                      <div className="p-[6px] px-[12px] w-full flex flex-col relative">
                        <p className="text-btn-secondary text-[18px] ">
                          {" "}
                          {tour?.tour_activity_name}
                        </p>
                        <div>
                          <div>
                            <p className="text-txt-primary text-[12px]">
                              Number of Days:{" "}
                            </p>
                          </div>
                          <div className="rounded">
                            <Select
                              value={activites?.activity_day_number}
                              onChange={(activity_day_number) =>
                                setActivites((prev) => ({
                                  ...prev,
                                  activity_day_number: activity_day_number,
                                }))
                              }
                              components={animatedComponents}
                              options={number_of_daysArray}
                              placeholder={"No of Day: "}
                              styles={colorStyles}
                            />
                          </div>
                        </div>

                        <div className="flex gap-x-[16px] mt-[4px]">
                          <div className="w-full">
                            <p className="text-[12px]">
                              Activity Start Time :{" "}
                            </p>
                            <input
                              onChange={(e) =>
                                setActivites((prev) => ({
                                  ...prev,
                                  activity_start_time: e.target.value,
                                }))
                              }
                              type="time"
                              id="session-time"
                              name="appt"
                              step="0"
                              value={activites?.activity_start_time}
                              required
                              style={{
                                padding: "4px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "16px",
                                width: "100%",
                                outline: "none",
                                backgroundColor: "#f5f5f5",
                                color: "#9e9989",
                                fontFamily: "Arial, sans-serif",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                transition:
                                  "border-color 0.3s ease, box-shadow 0.3s ease",
                              }}
                            />
                          </div>
                          <div className="w-full">
                            <p className="text-[12px]">Activity End Time : </p>
                            <input
                              onChange={(e) =>
                                setActivites((prev) => ({
                                  ...prev,
                                  activity_end_time: e.target.value,
                                }))
                              }
                              type="time"
                              id="session-time"
                              name="appt"
                              step="0"
                              value={activites?.activity_end_time}
                              required
                              style={{
                                padding: "4px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                fontSize: "16px",
                                width: "100%",
                                outline: "none",
                                backgroundColor: "#f5f5f5",
                                color: "#9e9989",
                                fontFamily: "Arial, sans-serif",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                transition:
                                  "border-color 0.3s ease, box-shadow 0.3s ease",
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-[140px] ml-auto mt-[8px] absolute bottom-[6px] right-[12px]">
                          <button
                            onClick={() =>
                              handleActivites(tour?.tour_activity_id)
                            }
                            className="bg-btn-primary py-[3px] text-bg-white w-full px-[20px]  border-btn-primary rounded-md shadow-md transition-all duration-500 hover:bg-btn-secondary hover:border-btn-secondary hover:text-bg-white hover:shadow-lg"
                          >
                            ADD
                          </button>
                        </div>
                      </div>
                    </div> */}
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-[30px]">
          <button
            onClick={() => navigate(merchantTourRoute.TOUR_DASHBOARD)}
            className="bg-[#DC7633] px-[40px] rounded-[4px] text-bg-white py-[8px]"
          >
            Back Tour List
          </button>
          <button
            disabled={
              tourSubscribe?.tour_starting_place !== undefined &&
              tourSubscribe?.tour_activities?.length === 0
            }
            onClick={() => subscribeTour()}
            className="bg-btn-secondary px-[40px] rounded-[4px] text-bg-white py-[8px] disabled:bg-[#A6ACAF]"
          >
            Subscribe
          </button>
        </div>
      </div>

      <ModalCommon
        shown={modalShown}
        width="max-w-[450px]"
        height="min-h-[200px]"
        outSideOff={true}
        close={() => {
          setToggleModal(false);
        }}
      >
        <div className="p-[30px] flex flex-col gap-y-[10px]">
          <div>
            <CustomInputWithDropdown
              setSelectedOption={(activity_day_number) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_day_number: activity_day_number,
                }))
              }
              selectedOption={activites?.activity_day_number}
              labelText={"Day Of Number"}
              options={generateArray(getTours?.number_of_days)}
            />
          </div>

          <div>
            <CustomInputWithDropdown
              setSelectedOption={(activity_id) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_id: activity_id,
                }))
              }
              selectedOption={activites?.activity_id?.label}
              labelText={"Activity Name"}
              options={activityVariable}
            />
          </div>

          <div className="w-full">
            <p className="text-[12px]">Activity Start Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_start_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_start_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>

          <div className="w-full">
            <p className="text-[12px]">Activity End Time : </p>
            <input
              onChange={(e) =>
                setActivites((prev) => ({
                  ...prev,
                  activity_end_time: e.target.value,
                }))
              }
              type="time"
              id="session-time"
              name="appt"
              step="0"
              value={activites?.activity_end_time}
              required
              style={{
                padding: "4px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
                width: "100%",
                outline: "none",
                backgroundColor: "#f5f5f5",
                color: "#9e9989",
                fontFamily: "Arial, sans-serif",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                transition: "border-color 0.3s ease, box-shadow 0.3s ease",
              }}
            />
          </div>
          <button
            // onClick={() => handleActivites()}
            className="h-[40px] w-full bg-btn-secondary rounded-[8px] text-bg-white mt-[10px]"
          >
            Submit
          </button>
        </div>
      </ModalCommon>
    </div>
  );
};

export default MarchantTourSubscribe;
