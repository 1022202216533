import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { MERCHANT_TOUR_ENDPOINT } from "../contants/Endpoints";
import { authAxios } from "../utils/axiosWrapper";
import TourSingleCard from "./Tours/TourSingleCard";

const AllTour = () => {
  const [allTourData, setAllTourData] = useState([]);

  const getAllTourList = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_TOURS_CREATED_TOUR_GET_API_ALL}?requestId=1`
      )
      .then((res) => {
        // console.log(res?.data?.tours);
        setAllTourData(res?.data?.tours);
      })
      .catch((err) => console.log(err));
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllTourList();
  }, []);

  return (
    <>
      <p className="text-btn-secondary text-center mb-[24px] xl:text-[20px] md:text-[18px] text-[16px]">
        All Tour
      </p>
      <div className="grid  mx-auto justify-center xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center gap-[8px] ">
        {allTourData &&
          allTourData?.map((tour, index) => (
            <TourSingleCard key={index} tour={tour} />
          ))}
      </div>
    </>
  );
};

export default AllTour;
