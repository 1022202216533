import React, { useEffect, useState } from "react";
import Select from "react-select";
import { MERCHANT_TOUR_ENDPOINT } from "../../contants/Endpoints";
import { authAxios } from "../../utils/axiosWrapper";
import SubscribeCard from "../Tours/SubscribeCart";
import { animatedComponents, colorStyles } from "../Utilities/SelectAnimation";
export default function WithoutSubscribeTour() {
  const [allTourData, setAllTourData] = useState([]);
  const [allTour, setAllTour] = useState({
    label: "Without Package",
    value: false,
  });
  const marchantId = localStorage.getItem("id");
  const getAllTourList = () => {
    let url = allTour?.value
      ? `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SUBSCRIPTION_WITH_PACKAGE}${marchantId}/SUBSCRIBED_TOUR_ALL?requestId=123`
      : `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_SUBSCRIPTION_WITH_PACKAGE}${marchantId}/SUBSCRIBED_TOUR_WITHOUT_ANY_PACKAGE?requestId=123`;
    authAxios
      .get(url)
      .then((res) => {
        // console.log(res?.data?.tours);
        setAllTourData(res?.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllTourList();
  }, [allTour]);

  const transferModeArray = [
    { label: "All", value: true },
    { label: "Without Package", value: false },
  ];

  console.log("allTourData", allTourData?.subscribed_tours);
  return (
    <>
      <div className="flex flex-end min-w-[250px] max-w-[250px] items-end ml-auto">
        <div className="rounded my-[20px] w-full min-w-[250px]">
          <Select
            value={allTour}
            onChange={(data) => setAllTour(data)}
            components={animatedComponents}
            options={transferModeArray}
            placeholder={"Select: "}
            styles={colorStyles}
          />
        </div>
      </div>
      <div className="w-full">
        {allTourData?.subscribed_tours && (
          <SubscribeCard tour={allTourData?.subscribed_tours} />
        )}
      </div>
    </>
  );
}
