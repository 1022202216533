import React from "react";
import { useNavigate } from "react-router";

export default function AvailibilitySetTable({ responseData }) {
  const navigate = useNavigate();
  console.log("responseData", responseData);

  return (
    <div>
      {/* <table className={`table-fixed rounded-[8px] border-collapse  w-full`}>
        <thead
          className={`sticky top-[88px] shadow-sm bg-[#48C9B0]  text-bg-primary  text-left`}
        >
          <tr>
            <th className=" px-[8px]  py-[10px]  ">Food </th>
            <th className="  py-[10px] px-[8px]  ">Accommodation </th>
            <th className="px-[8px]  py-[10px] ">Transfer </th>

            <th className=" px-[8px]  py-[10px]  ">Transportation</th>
            <th className=" px-[8px]  py-[10px]  ">Guide</th>
            <th className=" px-[8px]  py-[10px]  ">Spot </th>
          </tr>
        </thead>
        <tbody className="">
          {availableOption?.map((packageData, index) => (
            <tr
              key={index}
              className={`hover:bg-[#48c9af16] cursor-pointer border-b text-[14px] border-[#eaecef]`}
            >
              <td>
                {packageData?.tour_package_available_food_options?.map(
                  (foodOption, index) => (
                    <div>
                      {foodOption?.tour_package_available_meal_packages?.map(
                        (foods, index) => (
                          <div key={index}>
                            <div>
                              <p className="text-center text-[16px] bg-btn-secondary text-bg-white">
                                {foods?.meal_type_name}
                              </p>
                              <p className="flex gap-x-[6px]">
                                {foods?.food_items?.map((fds, ind) => (
                                  <span key={ind}>{fds?.food_item_name}</span>
                                ))}
                              </p>
                              <p>Price: {foods?.meal_package_price}</p>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
             
              <td>
                {packageData?.tour_package_available_accommodation_options?.map(
                  (packageData, index) => (
                    <div key={index}>
                      {packageData?.tour_package_available_accommodation_packages?.map(
                        (pkgData, ind) => (
                          <div key={ind}>
                            <p className="text-[16px] text-center bg-btn-secondary text-bg-white">
                              {pkgData?.tour_package_accommodation_name}
                            </p>
                            <p>
                              {" "}
                              {pkgData?.tour_package_room_category_name}{" "}
                              Category {pkgData?.tour_package_room_type_name}{" "}
                              type {pkgData?.bed_configuration} Bed
                            </p>
                            <p>Price {pkgData?.per_night_room_price}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_transfer_options?.map(
                  (trnsferPkg, index) => (
                    <div key={index}>
                      {trnsferPkg?.tour_package_available_transfer_packages?.map(
                        (trnsPkg, index) => (
                          <div key={index}>
                            <p className="text-[14px] bg-btn-secondary text-bg-white">
                              {trnsPkg?.tour_package_transfer_route}
                            </p>
                            <p>
                              {
                                trnsPkg?.tour_package_transportation_provider_name
                              }{" "}
                              {trnsPkg?.tour_package_transportation_mode_name}{" "}
                              {trnsPkg?.trip_type}
                            </p>
                            <p>Price : {trnsPkg?.per_vehicle_per_trip_price}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_transportation_packages?.map(
                  (trnsportation, index) => (
                    <div key={index}>
                      <p className="text-bg-white text-center bg-btn-secondary">
                        {trnsportation?.tour_package_transportation_route_name}
                      </p>
                      <p>
                        {
                          trnsportation?.tour_package_transportation_provider_name
                        }{" "}
                        {trnsportation?.tour_package_transportation_mode_name}{" "}
                        {trnsportation?.tour_package_transportation_brand_name}{" "}
                        brand
                      </p>
                      <p>{trnsportation?.trip_type}</p>
                      <p>
                        Price :{" "}
                        {trnsportation?.transportation_package_unit_price}
                      </p>
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_guide_options?.map(
                  (guidePkg, index) => (
                    <div key={index}>
                      {guidePkg?.tour_package_available_guide_packages?.map(
                        (guide, ind) => (
                          <div key={ind}>
                            <p>For Day : {guide?.number_of_guides_for_day}</p>
                            <p>Price: {guide?.total_guide_price_for_day}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
              <td>
                {packageData?.tour_package_available_spot_entry_options?.map(
                  (spotPkg, index) => (
                    <div key={index}>
                      {spotPkg?.tour_package_available_spot_entry_packages?.map(
                        (spot, ind) => (
                          <div key={ind}>
                            <p className="text-center bg-btn-secondary text-bg-white">
                              {spot?.activity?.activity_name}
                            </p>
                            <p>{spot?.activity?.activity_short_location}</p>
                            <p>{spot?.spot_entry_price_per_person}</p>
                          </div>
                        )
                      )}
                    </div>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table> */}

      <div>
        {responseData?.map((availableOptionData, index) => (
          <div key={index} className="mt-[10px] ">
            <div className="flex flex-col gap-[6px] text-[14px]">
              <h2 className="text-[16px]">
                Package Name: {availableOptionData?.tour_package_type_name}
              </h2>
              <p>Start Date : {availableOptionData?.tour_start_date}</p>
              <p>Total Seat : {availableOptionData?.total_seats}</p>
              <p>Bookable Seat : {availableOptionData?.bookable_seats}</p>
              <p>
                Accommodation Inclusive :{" "}
                {availableOptionData?.is_accommodation_inclusive ? "Yes" : "No"}
              </p>
              <p>
                Food Inclusive :{" "}
                {availableOptionData?.is_food_inclusive ? "Yes" : "No"}
              </p>
              <p>
                Guide Inclusive :{" "}
                {availableOptionData?.is_guide_inclusive ? "Yes" : "No"}
              </p>
              <p>
                {" "}
                Spot Inclusive :{" "}
                {availableOptionData?.is_spot_entry_inclusive ? "Yes" : "No"}
              </p>
              <p>
                {" "}
                Transfer Inclusive :{" "}
                {availableOptionData?.is_transfer_inclusive ? "Yes" : "No"}
              </p>
            </div>
            <h2 className="text-center text-[18px] bg-btn-secondary py-[3px] text-bg-white mt-[4px]">
              Tour Package Option {index + 1}
            </h2>
            {/* accommodation */}
            <div className="bg-[#FDFEFE] p-[10px] mt-[10px]">
              <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
                <h2>Accommodation</h2>
              </div>

              {availableOptionData?.tour_package_available_accommodation_options?.map(
                (optionData, ind) => (
                  <div key={ind} className="mt-[10px]">
                    <p className="bg-btn-secondary py-[3px] text-center text-bg-white">
                      Option {ind + 1}
                    </p>
                    <div>
                      <p>
                        Active :{" "}
                        {optionData?.tour_package_option_is_active
                          ? "Yes"
                          : "No"}
                      </p>
                      <p>
                        Price per person :{" "}
                        {optionData?.tour_package_option_price_per_person} BDT
                      </p>
                    </div>
                    <div>
                      {optionData?.tour_package_available_accommodation_packages && (
                        <div className="flex gap-x-[30px] mt-[10px] ">
                          {Object.keys(
                            optionData?.tour_package_available_accommodation_packages
                          )
                            ?.map(
                              (key) =>
                                optionData
                                  ?.tour_package_available_accommodation_packages[
                                  key
                                ]
                            )
                            ?.map((accommodation, index) => (
                              <div
                                key={index}
                                className="shadow-customShadow p-[10px] rounded-[6px] bg-bg-white"
                              >
                                <h2 className="text-center border-b border-btn-secondary">
                                  {
                                    accommodation?.tour_package_accommodation_name
                                  }
                                </h2>
                                <p>
                                  Category :{" "}
                                  {
                                    accommodation?.tour_package_room_category_name
                                  }
                                </p>
                                <p>
                                  Type:{" "}
                                  {accommodation?.tour_package_room_type_name}
                                </p>
                                <p>Bed : {accommodation?.bed_configuration}</p>
                                <p>Bed Number : {accommodation?.bed_count}</p>
                                <p>
                                  Shareable :{" "}
                                  {accommodation?.is_room_shareable
                                    ? "Yes"
                                    : "No"}
                                </p>
                                <p>
                                  Night Number: {accommodation?.night_number}
                                </p>
                                <p>
                                  Price: {accommodation?.per_night_room_price}
                                </p>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>

            {/* Food */}
            <div className="mt-[10px] bg-bg-white p-[10px]">
              <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
                <h2>Food Package</h2>
              </div>

              <div>
                {availableOptionData?.tour_package_available_food_options?.map(
                  (foodOption, ind) => (
                    <div key={ind} className="flex flex-col  gap-[30px]">
                      <div key={ind} className="mt-[20px] bg-[#EAECEE] p-[8px]">
                        <div>
                          <p>
                            Active :{" "}
                            {foodOption?.tour_package_option_is_active
                              ? "Yes"
                              : "No"}
                          </p>
                          <p>
                            Price Per Person :{" "}
                            {foodOption?.tour_package_option_price_per_person}{" "}
                            BDT
                          </p>
                        </div>
                        {foodOption?.tour_package_available_meal_packages && (
                          <div className="flex  ">
                            {foodOption?.tour_package_available_meal_packages && (
                              <div className="max-h-[600px] overflow-y-scroll">
                                <div className="bg-[#F4F6F6] w-full p-[6px]">
                                  {Object.keys(
                                    foodOption?.tour_package_available_meal_packages
                                  ).map((dayNumber) => (
                                    <div className="text-[14px] ">
                                      <p className="bg-btn-secondary text-bg-white">
                                        Day {dayNumber} Meal Option
                                      </p>
                                      <div className="flex gap-[40px]">
                                        {Object.keys(
                                          foodOption
                                            ?.tour_package_available_meal_packages[
                                            dayNumber
                                          ]
                                        ).map((mealType) => (
                                          <div>
                                            <p className="text-[18px] font-bold">
                                              {
                                                foodOption
                                                  ?.tour_package_available_meal_packages[
                                                  dayNumber
                                                ][mealType]?.meal_type_name
                                              }
                                            </p>
                                            <div>
                                              {Object.values(
                                                foodOption
                                                  ?.tour_package_available_meal_packages[
                                                  dayNumber
                                                ][mealType]?.food_items
                                              ).join(", ")}
                                            </div>
                                            <p>
                                              Meal Package Price:{" "}
                                              {
                                                foodOption
                                                  ?.tour_package_available_meal_packages[
                                                  dayNumber
                                                ][mealType]?.meal_package_price
                                              }{" "}
                                              BDT
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Transportation */}
            <div className="bg-bg-white mt-[20px] p-[10px]">
              <h2 className="bg-btn-primary text-bg-white py-[3px] ">
                Transportation{" "}
              </h2>
              <div className="flex flex-wrap gap-[30px] p-[10px]">
                {availableOptionData?.tour_package_available_transportation_packages?.map(
                  (transportationOption, index) => (
                    <div key={index} className="p-[10px] bg-[#EBF5FB]">
                      <p className="border-b ">Option {index + 1}</p>
                      <h2>
                        Route Name :
                        {
                          transportationOption?.tour_package_transportation_route_name
                        }
                      </h2>
                      <p>
                        Mode :{" "}
                        {
                          transportationOption?.tour_package_transportation_mode_name
                        }
                      </p>
                      <p>
                        Provider Name :{" "}
                        {
                          transportationOption?.tour_package_transportation_provider_name
                        }
                      </p>
                      <p>
                        Brand :{" "}
                        {
                          transportationOption?.tour_package_transportation_brand_name
                        }
                      </p>
                      <p>Ac : {transportationOption?.is_ac ? "Yes" : "No"}</p>
                      <p>Trip Type : {transportationOption?.trip_type}</p>
                      <p>
                        Unit Per Price :{" "}
                        {
                          transportationOption?.transportation_package_unit_price
                        }{" "}
                        BDT
                      </p>
                      <p>
                        Active :{" "}
                        {transportationOption?.transportation_package_is_active
                          ? "Yes"
                          : "No"}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Transfer */}
            <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
              <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
                <h2>Transfer</h2>
              </div>

              <div>
                {availableOptionData?.tour_package_available_transfer_options?.map(
                  (transferDataOption, ind) => (
                    <div key={ind}>
                      <p className="border-b ">Option {ind + 1}</p>
                      <div>
                        <p>
                          Active :{" "}
                          {transferDataOption?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          Price Per Person :{" "}
                          {
                            transferDataOption?.tour_package_option_price_per_person
                          }{" "}
                          BDT
                        </p>
                      </div>
                      <div className="flex gap-x-[10px]">
                        {transferDataOption?.tour_package_available_transfer_packages?.map(
                          (transferData, ind) => (
                            <div
                              key={ind}
                              className="shadow-customShadow p-[6px] rounded-[6px]"
                            >
                              <h2>
                                {transferData?.tour_package_transfer_route}
                              </h2>
                              <p>
                                Mode :{" "}
                                {
                                  transferData?.tour_package_transportation_mode_name
                                }
                              </p>
                              <p>
                                Provider :{" "}
                                {
                                  transferData?.tour_package_transportation_provider_name
                                }
                              </p>
                              <p>
                                Suitable for :{" "}
                                {transferData?.suitable_for_persons} Person
                              </p>
                              <p>Trip Type : {transferData?.trip_type}</p>
                              <p>Ac :{transferData?.is_ac ? "Yes" : "No"}</p>
                              <p>
                                Price :{" "}
                                {transferData?.per_vehicle_per_trip_price} BDT
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* Guide */}
            <div className="mt-[10px] bg-bg-white p-[10px]">
              <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
                <h2>Guide</h2>
              </div>

              {availableOptionData?.tour_package_available_guide_options?.map(
                (guideDataOption, ind) => (
                  <div key={ind}>
                    <p className="border-b">Option {ind + 1}</p>
                    <div>
                      <p>
                        Active :{" "}
                        {guideDataOption?.tour_package_option_is_active
                          ? "Yes"
                          : "No"}
                      </p>
                      <p>
                        Price per person :{" "}
                        {guideDataOption?.tour_package_option_price_per_person}{" "}
                        BDT
                      </p>
                    </div>

                    <div className="flex  gap-x-[10px] mt-[10px]">
                      {guideDataOption?.tour_package_available_guide_packages?.map(
                        (guideData, ind) => (
                          <div
                            key={ind}
                            className="shadow-customShadow p-[6px] rounded-[4px]"
                          >
                            <p>
                              For Day : {guideData?.number_of_guides_for_day}
                            </p>
                            <p>
                              Price : {guideData?.total_guide_price_for_day} BDT
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
            {/* Spot */}
            <div className="mt-[10px] bg-[#FDFEFE] p-[10px]">
              <div className="bg-btn-primary p-[3px] text-bg-white flex justify-between items-center">
                <h2>Spot</h2>
              </div>

              <div>
                {availableOptionData?.tour_package_available_spot_entry_options?.map(
                  (spotDataOption, ind) => (
                    <div key={ind}>
                      <p className="border-b">Option {ind + 1}</p>
                      <div>
                        <p>
                          Active :{" "}
                          {spotDataOption?.tour_package_option_is_active
                            ? "Yes"
                            : "No"}
                        </p>
                        <p>
                          Price per person:{" "}
                          {spotDataOption?.tour_package_option_price_per_person}{" "}
                          BDT
                        </p>
                      </div>
                      <div className=" flex gap-x-[10px] ">
                        {spotDataOption?.tour_package_available_spot_entry_packages?.map(
                          (spotData, ind) => (
                            <div
                              key={ind}
                              className="shadow-customShadow p-[6px] rounded-[4px] mt-[10px]"
                            >
                              <p>{spotData?.activity?.activity_name}</p>
                              <p>
                                {spotData?.activity?.activity_short_location}
                              </p>
                              <p>
                                Price per person :{" "}
                                {spotData?.spot_entry_price_per_person} BDT
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <button
        onClick={() => navigate("/dashboard/tours/active-tour")}
        className="text-bg-white text-[18px] bg-btn-secondary h-[39px] px-[10px] rounded-[4px] mt-[30px]"
      >
        Back Tour
      </button>
    </div>
  );
}
