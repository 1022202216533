import { getAuth, onAuthStateChanged } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import AddHotel from "./components/AddHotel";
import CategoriesTableComponent from "./components/CategoriesTableComponent";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
import DashboardHotelList from "./components/dashboardSupport/DashboardHotelList";
import HomePage from "./components/homePage/HomePage";
import AdminLayout from "./components/layouts/AdminLayout";
import Loading from "./components/Loader/Loading";
import ModalGlobal from "./components/ModalGlobal";
import CategoriesComponent from "./components/root/CategoriesComponent";
import ContractComponent from "./components/root/ContractComponent";
import HotelComponent from "./components/root/HotelComponent";
import OwnerComponent from "./components/root/OwnerComponent";
import Documents from "./components/root/registration/Documents";
import RegistrationLanding from "./components/root/registration/RegistrationLanding";
import {
  adminRoute,
  BlogMarchant,
  DashboardRoutes,
  merchantExcursionRoute,
  merchantRoute,
  merchantTourRoute,
  SummeryStatus,
  UserType,
} from "./contants/Constants";
import AdminAddLocation from "./pages/admin/AdminAddLocation";
import AdminPriceCalc from "./pages/admin/AdminCalculator";
import AdminCancelledBookings from "./pages/admin/AdminCancelledBookings";
import AdminCategoryReview from "./pages/admin/AdminCategoryReview";
import AdminCheleKhelaSync from "./pages/admin/AdminCheleKhela";
import AdminCreateUser from "./pages/admin/AdminCreateUser";
import AdminDealsTool from "./pages/admin/AdminDealsTool";
import AdminFacilityManage from "./pages/admin/AdminFacilityManage";
import DispalayGroup from "./pages/admin/AdminFacilityToll/DisplayGroup";

import AdminCouponMangerNew from "./pages/admin/AdminCouponManagerNew";
import PropertyFacility from "./pages/admin/AdminFacilityToll/PropertyFacility";
import PropertyFacilityType from "./pages/admin/AdminFacilityToll/PropertyFacilityType";
import RoomCategoryFilter from "./pages/admin/AdminFacilityToll/RoomCategoryFilter";
import RoomCategoryType from "./pages/admin/AdminFacilityToll/RoomCategoryType";
import UiFilter from "./pages/admin/AdminFacilityToll/UiFilter";
import AdminFaq from "./pages/admin/AdminFaq";
import AdminHotelDetails from "./pages/admin/AdminHotelDetails";
import AdminHotelReview from "./pages/admin/AdminHotelReview";
import AdminHotelTNC from "./pages/admin/AdminHotelTNC/AdminHotelTNC";
import AdminTNCToll from "./pages/admin/AdminHotelTNC/AdminTNCToll";
import AdminHotelFacility from "./pages/admin/AdminHotelTool/AdminFacilitySetToll/AdminHotelFacility";
import AdminHotelSpecialityTool from "./pages/admin/AdminHotelTool/AdminHotelSpecialityTool/AdminHotelSpecialityTool";
import AdminAddThumbImage from "./pages/admin/AdminHotelTool/AdminThumbSetToll/AdminAddThumbImage";
import AdminInspiredLocations from "./pages/admin/AdminInspiredLocations";
import AdminInvoiceList from "./pages/admin/AdminInvoiceList";
import AdminInvoiceTool from "./pages/admin/AdminInvoiceTool";
import AdminCreateInvoice from "./pages/admin/adminInvoiceTools/AdminCreateInvoice";
import AdminCreatePaymentMethod from "./pages/admin/adminInvoiceTools/AdminCreatePaymentMethod";
import AdminCreatePaymentSlip from "./pages/admin/adminInvoiceTools/AdminCreatePaymentSlip";
import AdminInvoiceDetails from "./pages/admin/adminInvoiceTools/AdminInvoiceDetails";
import AdminInvoiceRecords from "./pages/admin/adminInvoiceTools/AdminInvoiceRecords";
import AdminVerifyPayment from "./pages/admin/adminInvoiceTools/AdminVerifyPayment";
import AdminLanding from "./pages/admin/AdminLanding";
import AdminNonExpressBookings from "./pages/admin/AdminNonExpressBokkings";
import AdminPayments from "./pages/admin/AdminPayments";
import AdminSetPrice from "./pages/admin/adminPriceTools/AdminSetPrice";
import AdminRejectedBookings from "./pages/admin/AdminRejectedBookings";
import AdminSetRatings from "./pages/admin/AdminSetRatings/AdminSetRatings";
import AllContracts from "./pages/admin/AllContracts";
import AllDirectBookings from "./pages/admin/AllDirectBookings";
import CrewManagement from "./pages/admin/Dashboard/CrewManagement";
import HotelTourFlowControl from "./pages/admin/Dashboard/HotelTourFlowControl";
import MerchantDashboardCategories from "./pages/admin/Dashboard/MerchantDashboardCategories";
import AdminDashboard from "./pages/admin/Dashboard/MerchantDashboardMain";
import PolicyManagement from "./pages/admin/Dashboard/PolicyManagement";
import GenarelTNCTool from "./pages/admin/GenarelTNCTool/GenarelTNCTool";
import IconAndImages from "./pages/admin/IconAndImage";
import LiveHotelToll from "./pages/admin/LiveHotelToll/LiveHotelToll";
import AdminViewAllTour from "./pages/admin/NewTourCreateToll/AdminViewAllTour";
import TourAccomandationAdd from "./pages/admin/NewTourCreateToll/TourAccomandationAdd";
import TourAccomandationType from "./pages/admin/NewTourCreateToll/TourAccomandationType";
import TourActivityAddDashboard from "./pages/admin/NewTourCreateToll/TourActivityAddDashboard";
import TourActivityTypeDashboard from "./pages/admin/NewTourCreateToll/TourActivityTypeDashboard";
import TourAddedDashboard from "./pages/admin/NewTourCreateToll/TourCreateDashboard";
import TourCreatedDashboard from "./pages/admin/NewTourCreateToll/TourCreatedDashboard";
import TourFoodItemName from "./pages/admin/NewTourCreateToll/TourFoodItemName";
import TourMealTypeDashboard from "./pages/admin/NewTourCreateToll/TourMealTypeDashboard";
import TourPackageAdd from "./pages/admin/NewTourCreateToll/TourPackageAdd";
import TourRoomCategory from "./pages/admin/NewTourCreateToll/TourRoomCategory";
import TourRoomType from "./pages/admin/NewTourCreateToll/TourRoomType";
import TransportBrands from "./pages/admin/NewTourCreateToll/TransportTool/TransportBrands";
import TransportMode from "./pages/admin/NewTourCreateToll/TransportTool/TransportMode";
import TransportProvider from "./pages/admin/NewTourCreateToll/TransportTool/TransportProvider";
import TransportRoute from "./pages/admin/NewTourCreateToll/TransportTool/TransportRoute";
import RolePermission from "./pages/admin/RolePermission";
import RolePermissionAssign from "./pages/admin/RolePermissionAssign";
import Roles from "./pages/admin/Roles";
import TermsAndCondtionsIndex from "./pages/admin/termsAndConditions";
import TopHotelSet from "./pages/admin/TopHotelSet/TopHotelSet";
import AdminTourAddCategory from "./pages/admin/Tour/AdminTourAddCat";
import AdminTourCreate from "./pages/admin/Tour/AdminTourCreate";
import AdminTourEdit from "./pages/admin/Tour/AdminTourEdit";
import AdminTourEditCategory from "./pages/admin/Tour/AdminTourEditCat";
import AdminTourList from "./pages/admin/Tour/AdminTourList";
import AdminTourManage from "./pages/admin/Tour/AdminTourManage";
import NewTourCreate from "./pages/admin/TourManagement/NewTourCreate";
import BookingReview from "./pages/BookingReview";
import BookingReviewAdmin from "./pages/BookingReviewAdmin";
import BookingReviewByMarchent from "./pages/BookingReviewByMarchent";
import BookingSalesOrderReviewAdmin from "./pages/BookingSalesOrderReviewAdmin";
import DashboardBooking from "./pages/DashboardBooking";
import LogIn from "./pages/LogIn";
import NotFound from "./pages/NotFound";
import Privacy from "./pages/privacy";
import SearchByOrderCode from "./pages/SearchByOrderCode";
import SignIn from "./pages/SignIn";
import Terms from "./pages/terms";
import "./selectscrollbar.css";
import { fetchSummeryGet } from "./store/registration/landingAccessFetchSummery/api/AccessFetchSummeryApi";
import { TestComponent } from "./test";
import Abibility from "./ToursMarchent/Abibility/Abibility";
import AbibilityPackage from "./ToursMarchent/Abibility/AbibilityPackage";
import AbibilityPackageOption from "./ToursMarchent/Abibility/AbibilityPackageOption";
import ActiveTour from "./ToursMarchent/ActiveTour/ActiveTour";
import AccomodationPackage from "./ToursMarchent/AllPackages/AccomodationPackage";
import CreatePackage from "./ToursMarchent/AllPackages/CreatePackage";
import FoodPackage from "./ToursMarchent/AllPackages/FoodPackages/FoodPackage";
import PackageType from "./ToursMarchent/AllPackages/PackageType";
import ParentPackageSelected from "./ToursMarchent/AllPackages/ParentPackageSelected";
import TourPackageSeleted from "./ToursMarchent/AllPackages/TourPackageSeleted";
import TransportationPackage from "./ToursMarchent/AllPackages/TransportationPackage";
import AllTour from "./ToursMarchent/AllTour";
import SubscribeTour from "./ToursMarchent/SubscribeTour/SubscribeTour";
import WithoutSubscribeTour from "./ToursMarchent/SubscribeTour/WithoutSubscribeTour";
import OptionTable from "./ToursMarchent/TourPrice/OptionTable";
import MarchantTourSubscribe from "./ToursMarchent/Tours/MarchantTourSubscribe";
import ToursLayout from "./ToursMarchent/ToursLayout";
import PrivateRoute from "./utils/privateRoute";

import ActFacilities from "./pages/admin/AdminActivityController/ActFacilities";
import ActFacilityGroup from "./pages/admin/AdminActivityController/ActFacilityGroup";

import NewBlog from "./components/blogMarchant/NewBlog";
import ExcursionCreateContainer from "./excursionMarchant/ExcursionCreateContainer";
import ExcursionDashboard from "./excursionMarchant/ExcursionDashboard";
import ExcursionLayout from "./excursionMarchant/ExcursionLayout";
import ExcursionCreateOption from "./excursionMarchant/excursionOptions/ExcursionCreateOption";
import ActPackageVariable from "./pages/admin/AdminActivityController/ActPackageVariable";
import AdminSetReview from "./pages/admin/AdminSetRatings/AdminSetReview";
import GroupOne from "./pages/admin/Excursion/GroupOne";
import GroupThree from "./pages/admin/Excursion/GroupThree";
import GroupTwo from "./pages/admin/Excursion/GroupTwo";
import ExcursionComponent from "./pages/admin/ExcursionController/ExcursionComponent";
import ExcursionSpeciality from "./pages/admin/ExcursionController/ExcursionSpeciality";
import AbibilitySetV2 from "./ToursMarchent/AllPackages/PackageV2/AbibilitySetV2";
import Accommondation from "./ToursMarchent/AllPackages/PackageV2/Accommondation";
import Food from "./ToursMarchent/AllPackages/PackageV2/Food";
import Guide from "./ToursMarchent/AllPackages/PackageV2/Guide";
import SpotFeeV2 from "./ToursMarchent/AllPackages/PackageV2/SpotFeeV2";
import Transfer from "./ToursMarchent/AllPackages/PackageV2/Transfer";
import Transportation from "./ToursMarchent/AllPackages/PackageV2/Transportation";

import AllBlogs from "./components/blogMarchant/AllBlogs";
import AdminReviewRoot from "./components/root/AdminReviewRoot";
import ExcursionMerchantPriceTools from "./excursionMarchant/priceTools/ExcursionMerchantPriceTools";
import AdminFaqGroupId from "./pages/admin/AdminFaqGroupId";
import AdminForTourBookingReview from "./pages/admin/AdminTourBookings/AdminForTourBookingReview";
import TourBookingReviewByOparetor from "./pages/admin/AdminTourBookings/TourBookingReviewByOparetor";
import BlogTags from "./pages/admin/Blogs/BlogTags";
import ProductTNCTool from "./pages/admin/GenarelTNCTool/ProductTNCTool";
import BookingAllSearchAndSeeDetails from "./pages/BookingAllSearchAndSeeDetails";
import TourDefaultOptionView from "./ToursMarchent/AllPackages/PackageV2/PackageView/TourDefaultOptionView";
import MerchantTourBookingsLayout from "./ToursMarchent/BookingsTourDashboard/MerchantTourBookingsLayout";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  // let history = useHistory();
  const [userType, setUserType] = useState(process.env.REACT_APP_USER_TYPE);

  const fetchSummeryData = useSelector((state) => state.fetchSummeryReducer);
  // console.log(fetchSummeryData.data.contractId, "fetchSummeryData");

  // localStorage.setItem(
  //   "contractId",
  //   fetchSummeryData.data ? fetchSummeryData.data.contractId : null
  // );
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("contractId")) {
      dispatch(fetchSummeryGet(localStorage.getItem("id")));
    }
  }, []);
  const id = JSON.parse(localStorage.getItem("id"));
  useEffect(() => {
    if (id === null) {
      navigate("/login");
    }
    console.log("fetchSummeryData", id);
  }, [id]);

  // useEffect(() => {
  //   if(loggedIn){
  //     if(userType == UserType.ADMIN){
  //       console.log(userType == UserType.ADMIN, "ywfrhefuhreu")
  //       navigate(adminRoute.ADMIN_LANDING)
  //     }else{
  //       console.log(userType == UserType.ADMIN, "ywfrhefuhreu")
  //       navigate(merchantRoute.LANDING)
  //     }
  //   }else{
  //     console.log(userType == UserType.ADMIN, "ywfrhefuhreu")
  //     navigate(merchantRoute.SIGNIN)
  //   }
  // }, [loggedIn])

  useEffect(() => {
    if (
      fetchSummeryData.data.status === SummeryStatus.ACCEPTED &&
      userType === UserType.USER
    ) {
      // when registration accepted it will autometic move to dashboard
      // navigate(DashboardRoutes.DASHBOARD);
    }
    if (!fetchSummeryData.Loading) {
      setIsLoading(false);
    }
  }, [fetchSummeryData]);

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
    } else {
      // navigate(merchantRoute.SIGNIN);
    }
  });

  if (isLoading) {
    return (
      <div className="w-full min-h-screen flex justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="bg-bg-primary">
      <Header userType={userType} />
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
      {userType === "ADMIN" ? (
        <div className="bg-bg-primary">
          <div className="2xl:max-w-full bg-bg-primary min-h-screen border ">
            <Routes>
              <Route
                path={adminRoute.ADMIN_LANDING}
                element={<AdminLayout Component={AdminLanding} />}
              />
              <Route
                path={adminRoute.ADMIN_ROLES}
                element={<AdminLayout Component={Roles} />}
              />
              <Route
                path={adminRoute.ADMIN_TERMS_CONDITIONS}
                element={<AdminLayout Component={TermsAndCondtionsIndex} />}
              />
              <Route
                path={adminRoute.ADMIN_SYNC_HOTEL}
                element={<AdminLayout Component={AdminCheleKhelaSync} />}
              />
              <Route
                path={adminRoute.ADMIN_TOURS}
                element={<AdminLayout Component={AdminTourList} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_MANAGE}
                element={<AdminLayout Component={AdminTourManage} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_CREATE}
                element={<AdminLayout Component={AdminTourCreate} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_Details}
                element={<AdminLayout Component={AdminTourEdit} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ADD_CATEGORY}
                element={<AdminLayout Component={AdminTourAddCategory} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_EDIT_CATEGORY}
                element={<AdminLayout Component={AdminTourEditCategory} />}
              />
              <Route
                path={adminRoute.ADMIN_PERMISSIONS}
                element={<AdminLayout Component={RolePermission} />}
              />
              <Route
                path={adminRoute.ADMIN_DEAL_TOOL}
                element={<AdminLayout Component={AdminDealsTool} />}
              />
              <Route
                path={adminRoute.ADMIN_ALL_INVOICE}
                element={<AdminLayout Component={AdminInvoiceList} />}
              />
              <Route
                path={adminRoute.ADMIN_INVOICE_TOOL}
                element={<AdminLayout Component={AdminInvoiceTool} />}
              />
              {/* ifcode */}
              <Route
                path={adminRoute.ADMIN_CREATE_INVOICE}
                element={<AdminLayout Component={AdminCreateInvoice} />}
              />
              <Route
                path={adminRoute.ADMIN_ALL_INVOICE_RECORDS}
                element={<AdminLayout Component={AdminInvoiceRecords} />}
              />
              <Route
                path={`${adminRoute.ADMIN_SINGLE_INVOICE_DETAILS}:id`}
                element={<AdminLayout Component={AdminInvoiceDetails} />}
              />
              <Route
                path={`${adminRoute.ADMIN_CREATE_PAYMENT_SLIP}:id`}
                element={<AdminLayout Component={AdminCreatePaymentSlip} />}
              />
              <Route
                path={adminRoute.ADMIN_CREATE_PAYMENT_METHOD}
                element={<AdminLayout Component={AdminCreatePaymentMethod} />}
              />
              <Route
                path={adminRoute.ADMIN_VERIFY_PAYMENTS}
                element={<AdminLayout Component={AdminVerifyPayment} />}
              />
              {/* <Route
                path={adminRoute.ADMIN_COUPON_MANAGE}
                element={<AdminLayout Component={AdminCouponMange} />}
              /> */}
              <Route
                path={adminRoute.ADMIN_COUPON_MANAGE}
                element={<AdminLayout Component={AdminCouponMangerNew} />}
              />
              <Route
                path={adminRoute.ADMIN_FACILITY_MANAGE}
                element={<AdminLayout Component={AdminFacilityManage} />}
              />
              <Route
                path={adminRoute.ADMIN_ICON_MANAGE}
                element={<AdminLayout Component={IconAndImages} />}
              />
              <Route
                path={adminRoute.ADMIN_BLOG_POST_CREATE_TAGS}
                element={<AdminLayout Component={BlogTags} />}
              />
              <Route
                path={adminRoute.ADMIN_CREATE_USER}
                element={<AdminLayout Component={AdminCreateUser} />}
              />
              <Route
                path={adminRoute.ADMIN_NON_EXPRESS_BOOKINGS}
                element={<AdminLayout Component={AdminNonExpressBookings} />}
              />
              <Route
                path={adminRoute.ADMIN_PERMISSIONS_ASSIGN}
                element={<AdminLayout Component={RolePermissionAssign} />}
              />
              <Route
                path={adminRoute.ADMIN_SUBMITTED_CONTRACTS}
                element={<AdminLayout Component={AllContracts} />}
              />
              <Route
                path={adminRoute.ADMIN_ALL_CONTRACTS}
                element={<AdminLayout Component={AllContracts} />}
              />
              <Route
                path={adminRoute.ADMIN_PAYMENTS}
                element={<AdminLayout Component={AdminPayments} />}
              />
              <Route
                path={adminRoute.ADMIN_SET_HOTEL_PRICE}
                element={<AdminLayout Component={AdminSetPrice} />}
              />
              <Route
                path={adminRoute.ADMIN_CALCULATOR}
                element={<AdminLayout Component={AdminPriceCalc} />}
              />
              <Route
                path={adminRoute.ADMIN_FAQ}
                element={<AdminLayout Component={AdminFaq} />}
              />
              <Route
                path={`${adminRoute.ADMIN_FAQ_GROUP_ID}:id`}
                element={<AdminLayout Component={AdminFaqGroupId} />}
              />
              <Route
                path={adminRoute.ADMIN_REVIEW_HOTEL}
                element={<AdminLayout Component={AdminHotelReview} />}
              />
              <Route
                path={adminRoute.ADMIN_HOTEL_DETAILS}
                element={<AdminLayout Component={AdminHotelDetails} />}
              />
              <Route
                path={adminRoute.ADMIN_REVIEW_CATEGORY}
                element={<AdminLayout Component={AdminCategoryReview} />}
              />
              {/* <Route
                path={adminRoute.ADMIN_OWNER}
                element={<AdminLayout Component={OwnerComponent} />}
              /> */}
              <Route
                path={adminRoute.ADMIN_OWNERV2}
                element={<AdminLayout Component={AdminReviewRoot} />}
              />

              <Route
                path={adminRoute.ADMIN_CONTRACT}
                element={<AdminLayout Component={ContractComponent} />}
              />
              {/* <Route
                path={adminRoute.ADMIN_BOOKINGS}
                element={<AdminLayout Component={AdminBookingAll} />}
              /> */}
              <Route
                path={adminRoute.ADMIN_BOOKINGSNEW}
                element={<AdminLayout Component={BookingReviewAdmin} />}
              />

              <Route
                path={adminRoute.ADMIN_SALESORDER}
                element={
                  <AdminLayout Component={BookingSalesOrderReviewAdmin} />
                }
              />
              <Route
                path={adminRoute.ADMIN_SALES_SEARCH_ADD_SEE_DETAILS}
                element={
                  <AdminLayout Component={BookingAllSearchAndSeeDetails} />
                }
              />
              <Route
                path={adminRoute.ADMIN_REJECTIONS_BOOKING}
                element={<AdminLayout Component={BookingReviewByMarchent} />}
              />
              <Route
                path={adminRoute.ADMIN_REJECTIONS_TOUR_BOOKING}
                element={
                  <AdminLayout Component={TourBookingReviewByOparetor} />
                }
              />
              <Route
                path={adminRoute.ADMIN_DIRECT_BOOKINGS}
                element={<AdminLayout Component={AllDirectBookings} />}
              />
              <Route
                path={adminRoute.ADMIN_REJECTIONS_BOOKINGS}
                element={<AdminLayout Component={AdminRejectedBookings} />}
              />
              <Route
                path={adminRoute.ADMIN_CANCELLATION_BOOKINGS}
                element={<AdminLayout Component={AdminCancelledBookings} />}
              />

              <Route
                path={adminRoute.ADMIN_TOURS_BOOKING}
                element={<AdminLayout Component={AdminForTourBookingReview} />}
              />
              <Route
                path={adminRoute.ADMIN_INSPIRED_LOCATION}
                element={<AdminLayout Component={AdminInspiredLocations} />}
              />
              <Route
                path={adminRoute.ADMIN_ADD_LOCATION}
                element={<AdminLayout Component={AdminAddLocation} />}
              />
              <Route
                path={adminRoute.ADMIN_DOCUMENT}
                element={<AdminLayout Component={Documents} />}
              />
              <Route
                path={adminRoute.ADMIN_HOTELS}
                element={<AdminLayout Component={AddHotel} />}
              />
              <Route
                path={`${adminRoute.ADMIN_HOTEL}:id`}
                element={<AdminLayout Component={HotelComponent} />}
              />
              <Route
                path={adminRoute.ADMIN_CATEGORIES}
                element={<AdminLayout Component={CategoriesTableComponent} />}
              />
              <Route
                path={`${adminRoute.ADMIN_CATEGORY}:id`}
                element={<AdminLayout Component={CategoriesComponent} />}
              />
              {/* rasel */}
              <Route
                path={adminRoute.ADMIN_THUMB_IMAGE}
                element={<AdminLayout Component={AdminAddThumbImage} />}
              />
              <Route
                path={adminRoute.ADMIN_THUMB_IMAGE_DETAILS}
                element={<AdminLayout Component={AdminAddThumbImage} />}
              />
              <Route
                path={adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY}
                element={<AdminLayout Component={AdminHotelFacility} />}
              />
              <Route
                path={adminRoute.ADMIN_FETCH_SINGLE_HOTEL_TNC}
                element={<AdminLayout Component={AdminTNCToll} />}
              />
              <Route
                path={adminRoute.ADMIN_SET_RATINGS}
                element={<AdminLayout Component={AdminSetRatings} />}
              />

              <Route
                path={adminRoute.ADMIN_SET_REVIEW}
                element={<AdminLayout Component={AdminSetReview} />}
              />
              <Route
                path={adminRoute.ADMIN_FETCH_ALL_HOTEL_TNC}
                element={<AdminLayout Component={AdminHotelTNC} />}
              />
              <Route
                path={adminRoute.ADMIN_FETCH_ALL_HOTEL_TOP}
                element={<AdminLayout Component={TopHotelSet} />}
              />
              <Route
                path={adminRoute.ADMIN_FETCH_ALL_HOTEL_FACILITY_DETAILS}
                element={<AdminLayout Component={AdminHotelFacility} />}
              />
              <Route
                path={adminRoute.ADMIN_SET_HOTEL_SPECIALITY}
                element={<AdminLayout Component={AdminHotelSpecialityTool} />}
              />
              <Route
                path={adminRoute.ADMIN_SET_HOTEL_SPECIALITY_DETAILS}
                element={<AdminLayout Component={AdminHotelSpecialityTool} />}
              />
              <Route
                path={adminRoute.ADMIN_PROPERTY_FACILITY_TOOL}
                element={<AdminLayout Component={PropertyFacility} />}
              />
              <Route
                path={adminRoute.ADMIN_DISPLAY_GROUP_TOOL}
                element={<AdminLayout Component={DispalayGroup} />}
              />
              <Route
                path={adminRoute.ADMIN_FACILITY_TYPE}
                element={<AdminLayout Component={PropertyFacilityType} />}
              />
              <Route
                path={adminRoute.ADMIN_UI_FILTER}
                element={<AdminLayout Component={UiFilter} />}
              />
              <Route
                path={adminRoute.ADMIN_ROOM_CATEGORY_FILTER}
                element={<AdminLayout Component={RoomCategoryFilter} />}
              />
              <Route
                path={adminRoute.ADMIN_ROOM_CATEGORY_TYPE}
                element={<AdminLayout Component={RoomCategoryType} />}
              />
              {/* tour management */}
              <Route
                path={adminRoute.ADMIN_TOUR_CREATE_TOOL}
                element={<AdminLayout Component={NewTourCreate} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_VIEW_TOOL}
                element={<AdminLayout Component={AdminViewAllTour} />}
              />

              <Route
                path={adminRoute.ADMIN_TOUR_ADDED_TOOL}
                element={<AdminLayout Component={TourAddedDashboard} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ACTIVITY_TYPE_TOOL}
                element={<AdminLayout Component={TourActivityTypeDashboard} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ACTIVITY_ADD_TOOL}
                element={<AdminLayout Component={TourActivityAddDashboard} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_CREATED_TOOL}
                element={<AdminLayout Component={TourCreatedDashboard} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_CREATED_FOOD_NAME}
                element={<AdminLayout Component={TourFoodItemName} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_CREATED_FOOD}
                element={<AdminLayout Component={TourMealTypeDashboard} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_PACKAGE_CREATE_TOOL}
                element={<AdminLayout Component={TourPackageAdd} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ACCOMANDATION_TYPE_TOOL}
                element={<AdminLayout Component={TourAccomandationType} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ACCOMANDATION_ADD_TOOL}
                element={<AdminLayout Component={TourAccomandationAdd} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ROOM_TYPE_TOOL}
                element={<AdminLayout Component={TourRoomType} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_ROOM_CATEGORY_TOOL}
                element={<AdminLayout Component={TourRoomCategory} />}
              />

              <Route
                path={adminRoute.ADMIN_TOUR_TRANSPORT_ROUTE_TOOL}
                element={<AdminLayout Component={TransportRoute} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_TRANSPORT_PROVIDER_TOOL}
                element={<AdminLayout Component={TransportProvider} />}
              />
              <Route
                path={adminRoute.ADMIN_TOUR_TRANSPORT_MODE_TOOL}
                element={<AdminLayout Component={TransportMode} />}
              />

              <Route
                path={adminRoute.ADMIN_TOUR_TRANSPORT_BRANDS_TOOL}
                element={<AdminLayout Component={TransportBrands} />}
              />

              {/* activity management */}
              <Route
                path={adminRoute.ADMIN_ACT_FACILITY_GROUP}
                element={<AdminLayout Component={ActFacilityGroup} />}
              />
              <Route
                path={adminRoute.ADMIN_ACT_FACILITY}
                element={<AdminLayout Component={ActFacilities} />}
              />
              <Route
                path={adminRoute.ADMIN_EXCURSION_PACKAGE_VARIABLE}
                element={<AdminLayout Component={ActPackageVariable} />}
              />

              {/* excursion group routes */}
              <Route
                path={adminRoute.ADMIN_EXCURSION_GROUP_ONE}
                element={<AdminLayout Component={GroupOne} />}
              />
              <Route
                path={adminRoute.ADMIN_EXCURSION_GROUP_TWO}
                element={<AdminLayout Component={GroupTwo} />}
              />
              <Route
                path={adminRoute.ADMIN_EXCURSION_GROUP_THREE}
                element={<AdminLayout Component={GroupThree} />}
              />
              {/* excursion component and speciality routes */}
              <Route
                path={adminRoute.ADMIN_EXCURSION_COMPONENT}
                element={<AdminLayout Component={ExcursionComponent} />}
              />
              <Route
                path={adminRoute.ADMIN_EXCURSION_SPECIALITY}
                element={<AdminLayout Component={ExcursionSpeciality} />}
              />

              <Route
                path={adminRoute.ADMIN_TNC_TOOL}
                element={<AdminLayout Component={GenarelTNCTool} />}
              />
              <Route
                path={adminRoute.ADMIN_TNC_TOOL_PRODUCT}
                element={<AdminLayout Component={ProductTNCTool} />}
              />
              <Route
                path={adminRoute.ADMIN_BRING_LIVE_TOOL}
                element={<AdminLayout Component={LiveHotelToll} />}
              />
              {/* <Route path="/admin" element={<AdminDashboard />} /> */}
              {/* <Route path="/form" element={<FormHandle />} /> */}
              {/* <Route path="/contract-status" element={<ContractStatus />} /> */}
              <Route
                path={merchantRoute.SIGNUP}
                element={<SignIn userType={UserType.ADMIN} />}
              />
              <Route
                path={merchantRoute.LOGIN}
                element={<LogIn userType={UserType.ADMIN} />}
              />
              {/* <Route path={adminRoute.ADMIN_THUMB_IMAGE} element={<AdminAddThumbImage/>} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      ) : (
        <div className="bg-bg-primary">
          <div className=" bg-bg-primary min-h-screen">
            <Routes>
              <Route path="/test" element={<TestComponent />} />
              <Route
                path={merchantRoute.SIGNUP}
                element={<SignIn userType={UserType.USER} />}
              />
              <Route
                path={merchantRoute.LOGIN}
                element={<LogIn userType={UserType.USER} />}
              />
              <Route path="/bookingModal" element={<DashboardBooking />} />
              <Route path={merchantRoute.HOME} element={<HomePage />} />
              <Route
                path={DashboardRoutes.BOOKING}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.BOOKING}
                    Component={BookingReview}
                  />
                }
              />
              <Route
                path={merchantRoute.LANDING}
                element={
                  <PrivateRoute
                    route={merchantRoute.LANDING}
                    Component={RegistrationLanding}
                  />
                }
              />
              <Route
                path={merchantRoute.OWNER}
                element={
                  <PrivateRoute
                    route={merchantRoute.OWNER}
                    Component={OwnerComponent}
                  />
                }
              />
              <Route
                path={merchantRoute.CONTRACT}
                element={
                  <PrivateRoute
                    route={merchantRoute.CONTRACT}
                    Component={ContractComponent}
                  />
                }
              />

              <Route
                path={merchantRoute.DOCUMENT}
                element={
                  <PrivateRoute
                    route={merchantRoute.DOCUMENT}
                    Component={Documents}
                  />
                }
              />

              <Route
                path={merchantRoute.HOTELS}
                element={
                  <PrivateRoute
                    route={merchantRoute.HOTELS}
                    Component={AddHotel}
                  />
                }
              />

              <Route
                path={`${merchantRoute.HOTEL}:id`}
                element={
                  <PrivateRoute
                    route={merchantRoute.HOTEL}
                    Component={HotelComponent}
                  />
                }
              />
              <Route
                path={merchantRoute.CATEGORIES}
                element={
                  <PrivateRoute
                    route={merchantRoute.CATEGORIES}
                    Component={CategoriesTableComponent}
                  />
                }
              />
              <Route
                path={`${merchantRoute.CATEGORY}:id`}
                element={
                  <PrivateRoute
                    route={merchantRoute.CATEGORY}
                    Component={CategoriesComponent}
                  />
                }
              />

              <Route
                path={DashboardRoutes.DASHBOARD}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.DASHBOARD}
                    Component={AdminDashboard}
                  />
                }
              />

              <Route
                path={DashboardRoutes.FLOWCONTROL}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.FLOWCONTROL}
                    Component={HotelTourFlowControl}
                  />
                }
              />

              <Route
                path={DashboardRoutes.POLICIES}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.POLICIES}
                    Component={PolicyManagement}
                  />
                }
              />
              <Route
                path={DashboardRoutes.HOTELS}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.HOTELS}
                    Component={DashboardHotelList}
                  />
                }
              />
              <Route
                path={DashboardRoutes.CREW}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.CREW}
                    Component={CrewManagement}
                  />
                }
              />

              <Route
                path={DashboardRoutes.ALL_BOOKINGS}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.ALL_BOOKINGS}
                    Component={SearchByOrderCode}
                  />
                }
              />

              <Route
                path={DashboardRoutes.CATEGORIES}
                element={
                  <PrivateRoute
                    route={DashboardRoutes.CATEGORIES}
                    Component={MerchantDashboardCategories}
                  />
                }
              />

              <Route
                path={`${DashboardRoutes.HOTEL_DETAILS}:id`}
                element={
                  <PrivateRoute
                    route={`${DashboardRoutes.HOTEL_DETAILS}:id`}
                    Component={HotelComponent}
                  />
                }
              />

              <Route
                path={`${DashboardRoutes.CATEGORIES_DETAILS}:id`}
                element={
                  <PrivateRoute
                    route={`${DashboardRoutes.CATEGORIES_DETAILS}:id`}
                    Component={CategoriesComponent}
                  />
                }
              />

              <Route
                path={`${DashboardRoutes.MARCHENT_FETCH_SINGLE_HOTEL_TNC}:id`}
                element={
                  <PrivateRoute
                    route={`${DashboardRoutes.MARCHENT_FETCH_SINGLE_HOTEL_TNC}:id`}
                    Component={AdminTNCToll}
                  />
                }
              />

              {/* excursion route */}
              <Route
                path={`${merchantExcursionRoute.EXCURSION_DASHBOARD}`}
                element={<ExcursionLayout Component={ExcursionDashboard} />}
              />

              <Route
                path={`${merchantExcursionRoute.EXCURSION_CREATE}`}
                element={
                  <ExcursionLayout Component={ExcursionCreateContainer} />
                }
              />
              <Route
                path={`${merchantExcursionRoute.EXCURSION_CREATE_OPTION}`}
                element={<ExcursionLayout Component={ExcursionCreateOption} />}
              />
              <Route
                path={`${merchantExcursionRoute.EXCURSION_CREATE_MERCHANT_PRICE_TOOLS}`}
                element={
                  <ExcursionLayout Component={ExcursionMerchantPriceTools} />
                }
              />

              {/* blog route */}
              <Route
                path={`${BlogMarchant.ALL_BLOGS}`}
                element={
                  <PrivateRoute
                    route={BlogMarchant.ALL_BLOGS}
                    Component={AllBlogs}
                  />
                }
              />

              <Route
                path={`${BlogMarchant.POST_BLOG}/:id`}
                element={
                  <PrivateRoute
                    route={BlogMarchant.POST_BLOG}
                    Component={NewBlog}
                  />
                }
              />

              {/* //tour route */}
              <Route
                path={merchantTourRoute.TOUR_DASHBOARD}
                element={<ToursLayout Component={AllTour} />}
              />
              <Route
                path={`${merchantTourRoute.CREATE_TOUR}:id`}
                element={<ToursLayout Component={CreatePackage} />}
              />
              <Route
                path={`${DashboardRoutes.TOUR_BOOKINGS}`}
                element={<MerchantTourBookingsLayout />}
              />

              <Route
                path={`${merchantTourRoute.CREATE_PACKAGE_INIT}:id`}
                element={<ToursLayout Component={ParentPackageSelected} />}
              />

              <Route
                path={merchantTourRoute.CREATE_TOUR_PACKAGE_TYPE}
                element={<ToursLayout Component={PackageType} />}
              />
              <Route
                path={merchantTourRoute.CREATE_TOUR_FOOD_PACKAGE}
                element={<ToursLayout Component={FoodPackage} />}
              />
              <Route
                path={merchantTourRoute.CREATE_TOUR_ACCOMODATION_PACKAGE}
                element={<ToursLayout Component={AccomodationPackage} />}
              />
              <Route
                path={merchantTourRoute.CREATE_TOUR_TRANSPORTATION_PACKAGE}
                element={<ToursLayout Component={TransportationPackage} />}
              />
              <Route
                path={`${merchantTourRoute.TOURONE}:id`}
                element={<ToursLayout Component={TourPackageSeleted} />}
              />
              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-accommondation/:id`}
                element={<ToursLayout Component={Accommondation} />}
              />

              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-transfer/:id`}
                element={<ToursLayout Component={Transfer} />}
              />

              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-transportaion/:id`}
                element={<ToursLayout Component={Transportation} />}
              />
              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-guide/:id`}
                element={<ToursLayout Component={Guide} />}
              />
              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-spot-fee/:id`}
                element={<ToursLayout Component={SpotFeeV2} />}
              />
              <Route
                path={`${merchantTourRoute.PACKAGE_CREATE}/add-food/:id`}
                element={<ToursLayout Component={Food} />}
              />
              <Route
                path={`${merchantTourRoute.MARCHANT_VIEW_PACKAGE_CREATE}:id`}
                element={<ToursLayout Component={TourDefaultOptionView} />}
              />
              <Route
                path={merchantTourRoute.TOUR_DASHBOARD}
                element={<ToursLayout Component={AllTour} />}
              />
              <Route
                path={`${merchantTourRoute.MY_TOUR}:id`}
                element={<ToursLayout Component={MarchantTourSubscribe} />}
              />
              <Route
                path={`${merchantTourRoute.ACTIVE_TOUR}`}
                element={<ToursLayout Component={ActiveTour} />}
              />
              <Route
                path={`${merchantTourRoute.SUBSCRIBE_TOUR}`}
                element={<ToursLayout Component={SubscribeTour} />}
              />
              <Route
                path={`${merchantTourRoute.PRICE_TOOL}:id`}
                element={<ToursLayout Component={OptionTable} />}
              />

              <Route
                path={`${merchantTourRoute.SUBSCRIBE_TOUR_WITH_PACKAGE}`}
                element={<ToursLayout Component={WithoutSubscribeTour} />}
              />

              <Route
                path={`${merchantTourRoute.ABIBILITY}:id`}
                element={<ToursLayout Component={Abibility} />}
              />
              <Route
                path={`${merchantTourRoute.MARCHANT_SET_ABIBILITY_V2}:id`}
                element={<ToursLayout Component={AbibilitySetV2} />}
              />
              <Route
                path={`${merchantTourRoute.ABIBILITY_PACKAGE_ID}:id`}
                element={<ToursLayout Component={AbibilityPackage} />}
              />
              <Route
                path={`${merchantTourRoute.ABIBILITY_PRICE_ABIBILITY}:id`}
                element={<ToursLayout Component={AbibilityPackageOption} />}
              />

              {/* <Route
                path={`${merchantTourRoute.TOURONE}`}
                element={
                  <PrivateRoute
                    route={`${merchantTourRoute.DASHBOARD}`}
                    Component={TourOne}
                  />
                }
              /> */}
              {/* 
                <Route
                  path={`${merchantTourRoute.TOURONE}`}
                element={<ToursLayout Component={AdminHotelSpecialityTool} />}
              /> */}

              <Route path="/ModalGlobal" element={<ModalGlobal />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      )}

      {process.env.REACT_APP_USER_TYPE !== "ADMIN" && <Footer />}
    </div>
  );
}

export default App;
