import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  apiEndPoint,
  baseUrl,
  MERCHANT_TOUR_ENDPOINT,
} from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import {
  animatedComponents,
  colorStyles,
} from "../../Utilities/SelectAnimation";
import { convertStringToObject } from "../ParentPackageSelected";

export default function Guide() {
  const navigate = useNavigate();
  const { id } = useParams();
  const decodedString = decodeURIComponent(id);
  const convertData = convertStringToObject(decodedString);

  const [guidePackageList, setGuidePackageList] = useState([]);
  const [guidePackage, setGuidePackage] = useState({
    number_of_guides_for_day: "",
    tour_guide_price_per_day: "",
    day_number: "",
  });
  const [getToursById, setGetToursById] = useState();

  useEffect(() => {
    authAxios
      .get(
        `${baseUrl + apiEndPoint.MARCHENT_GET_SINGLE_TOUR_API}${
          convertData.tour_id
        }?requestId=er34`
      )
      .then((res) => {
        setGetToursById(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  }, []);
  const numberOfDay = Array.from(
    { length: getToursById?.number_of_days },
    (_, index) => index + 1
  );
  const dayProvide = numberOfDay?.map((day) => ({
    label: `${day} Day`,
    value: day,
  }));

  console.log("getToursById", getToursById);

  function transformPayload(payload) {
    const newPayload = {
      day_number: payload.day_number.value,

      number_of_guides_for_day: parseInt(payload.number_of_guides_for_day),
      tour_guide_price_per_day: parseInt(payload.tour_guide_price_per_day),
    };

    return newPayload;
  }

  const handleSubmitGuide = () => {
    const newPayload = transformPayload(guidePackage);

    authAxios
      .post(`${baseUrl}${apiEndPoint.MARCHANT_ADD_GUIDE_PACKAGE}`, {
        requestId: "guide-add",
        subscribed_tour_id: convertData.subscribeId,
        tour_guide_package: newPayload,
      })
      .then((res) => {
        toast.success("Guide Add");
        setGuidePackage({
          number_of_guides_for_day: "",
          tour_guide_price_per_day: "",
          day_number: "",
        });
        getGuidePackage();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getGuidePackage = () => {
    authAxios
      .get(
        `${MERCHANT_TOUR_ENDPOINT?.MERCHANT_GET_GUIDE_ALL_PACKAGE}${convertData.subscribeId}?requestId=100`
      )
      .then((res) => {
        setGuidePackageList(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getGuidePackage();
  }, []);

  const transformObject = (originalObject) => {
    const b = {
      number_of_guides_for_day: originalObject.number_of_guides_for_day,
      tour_guide_price_per_day:
        originalObject.total_guide_price_for_day.toString(),
      day_number: {
        label: originalObject.day_number,
        value: originalObject.day_number,
      },
    };

    return b;
  };
  const handleEditGuide = (guide) => {
    const editGuide = transformObject(guide);
    setGuidePackage(editGuide);
    // console.log(editGuide);
  };
  return (
    <div>
      <div className="my-[10px] ">
        <button
          onClick={() => navigate(-1)}
          className="  bg-btn-secondary rounded-full h-[40px] w-[130px] text-bg-primary flex justify-start gap-x-[15px] px-[8px] items-center"
        >
          <span className="text-start text-[20px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </span>
          Back
        </button>
        <h2 className="text-[18px] text-center">Guide Add</h2>
      </div>
      <div>
        <div className="flex justify-start items-start gap-x-[170px]">
          <div className="mt-[16px] mb-[8px]  w-full">
            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Number of Guide: </p>
              </div>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Guide Number"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setGuidePackage((prev) => ({
                        ...prev,
                        number_of_guides_for_day: inputVal,
                      }));
                    }
                  }}
                  value={guidePackage?.number_of_guides_for_day}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Price Per Day: </p>
              </div>
              <div className="rounded">
                <input
                  className="w-full p-[8px] rounded border-[2px] border-[#afc1c8] hover:border-[#4E90A8]"
                  placeholder="Per Day price"
                  type="number"
                  onChange={(e) => {
                    const inputVal = e.target.value;

                    if (!isNaN(inputVal)) {
                      setGuidePackage((prev) => ({
                        ...prev,
                        tour_guide_price_per_day: inputVal,
                      }));
                    }
                  }}
                  value={guidePackage?.tour_guide_price_per_day}
                />
              </div>
            </div>

            <div className="my-[16px]">
              <div>
                <p className="text-btn-secondary">Day You Provide Gide: </p>
              </div>
              <div className="rounded">
                <Select
                  value={guidePackage?.day_number || ""}
                  onChange={(data) => {
                    setGuidePackage((prev) => ({
                      ...prev,
                      day_number: data,
                    }));
                  }}
                  components={animatedComponents}
                  options={dayProvide}
                  placeholder={"Select Day: "}
                  styles={colorStyles}
                />
              </div>
            </div>

            <button
              onClick={() => handleSubmitGuide()}
              className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white"
            >
              Submit
            </button>
          </div>
          {/* view */}
          <div className="w-full">
            <div className="flex flex-col  gap-[16px]">
              {guidePackageList?.map((guide, index) => (
                <div
                  key={index}
                  className="shadow-customShadow p-[10px] rounded-[4px] relative"
                >
                  <p>Guide Number : {guide?.number_of_guides_for_day}</p>
                  <p>Price : {guide?.total_guide_price_for_day}</p>
                  <p>Provided Day :</p>
                  <div className="flex gap-x-[6px] ">
                    <p
                      key={index}
                      className="bg-btn-secondary py-[4px] px-[6px] rounded-[4px] text-bg-white"
                    >
                      {guide?.day_number}
                    </p>
                  </div>
                  <span
                    // onClick={() => handleRemoveGuide(index)}
                    className="absolute right-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-[25px] h-[25px] text-btn-pink"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                  <span
                    onClick={() => handleEditGuide(guide)}
                    className="absolute left-[-12px] cursor-pointer top-[-12px]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </span>
                </div>
              ))}

              {guidePackageList?.length > 0 && (
                <button
                  onClick={() => navigate(-1)}
                  className="h-[39px] w-full bg-btn-secondary active:bg-btn-primary rounded-[4px] text-bg-white mt-[20px]"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
