import { combineReducers } from "redux";
import userDetailsReducer from "../store/user/reducers/userDetailsReducer";
import {
  adminContractGetReducer,
  categoriesDetailsGetReducer,
  categoriesInfoGetReducer,
  documentsInfoGetReducer,
  ownerInfoGetReducer,
} from "./admin/adminContract/reducers/adminContractReducer";
import { submitReviewReducer } from "./admin/review/reducers/reviewReducer";
import { ownerReducer } from "./registration//owner/reducers/ownerReducer";
import {
  allCategoriesMerchantReducer,
  categoryReducer,
} from "./registration/category/reducers/categoryReducer";
import { contractReducer } from "./registration/contract/reducers/contractReducer";
import { countryDetailsReducer } from "./registration/countryDetails/reducers/countryDetailsReducer";
import { hotelPropertyReducer } from "./registration/hotelProperty/reducers/contractReducer";
import { fetchSummeryReducer } from "./registration/landingAccessFetchSummery/reducers/fetchSummeryReducer";
import {
  assignFunctionsToRoleReducers,
  createFunctionReducer,
  createRoleReducer,
  fetchFunctionReducer,
  fetchFunctionsToRoleReducers,
  fetchRoleReducer,
} from "./user/reducers/roleFunctionReducer";
import { signInReducer } from "./user/reducers/signInReducer";
import { signUpReducer } from "./user/reducers/signUpReducer";
// import { hotelsInfoGetReducer } from "./admin/hotel/reducers/adminContractReducer";
import tourMealTypeReducer from "./admin/combinationPackageTour/reducers/tourMealTypeReducer";
import combinationPackageStore from "./admin/combinationPackageTour/reducers/tourPackageCombinationReducer";
import tourPackageCreatePayloadReducers from "./admin/combinationPackageTour/reducers/tourPackageCreatePayloadReducers";
import { contractInfoGetReducer } from "./admin/contract/reducers/adminContractReducer";
import {
  hotelsInfoDetailsGetReducer,
  hotelsInfoGetReducer,
} from "./admin/hotel/reducers/adminContractReducer";
import { categoryPostAvailabilityReducer } from "./marchantDashboard/categoryAvailability/reducers/categoryAvailabilityReducer";
import { categoryGetAvailabilityReducer } from "./marchantDashboard/categoryAvailabilityCalenderGet/reducers/categoryAvailabilityReducer";
import { termsAndConditionsReducer } from "./registration/termsAndConditions/reducers/termsAndConditionsReducer";

const rootReducer = combineReducers({
  userDetailsReducer: userDetailsReducer,
  signupReducer: signUpReducer,
  signinReducer: signInReducer,
  ownerReducer: ownerReducer,
  contractReducer: contractReducer,
  hotelPropertyReducer: hotelPropertyReducer,
  categoryReducer: categoryReducer,
  fetchSummeryReducer: fetchSummeryReducer,

  createRoleReducer: createRoleReducer,
  fetchRoleReducer: fetchRoleReducer,
  editRoleReducer: createFunctionReducer,
  deleteRoleReducer: createFunctionReducer,
  createFunctionReducer: createFunctionReducer,
  fetchFunctionReducer: fetchFunctionReducer,
  editFunctionReducer: createFunctionReducer,
  deleteFunctionReducer: createFunctionReducer,
  countryDetailsReducer: countryDetailsReducer,
  adminContractGetReducer: adminContractGetReducer,
  fetchFunctionsToRoleReducers: fetchFunctionsToRoleReducers,
  assignFunctionsToRoleReducers: assignFunctionsToRoleReducers,

  ownerInfoGetReducer: ownerInfoGetReducer,
  contractInfoGetReducer: contractInfoGetReducer,
  documentsInfoGetReducer: documentsInfoGetReducer,
  hotelsInfoGetReducer: hotelsInfoGetReducer,
  hotelsInfoDetailsGetReducer: hotelsInfoDetailsGetReducer,
  categoriesInfoGetReducer: categoriesInfoGetReducer,
  categoriesDetailsGetReducer: categoriesDetailsGetReducer,
  submitReviewReducer: submitReviewReducer,
  allCategoriesMerchantReducer: allCategoriesMerchantReducer,

  categoryGetAvailabilityReducer: categoryGetAvailabilityReducer,
  categoryPostAvailabilityReducer: categoryPostAvailabilityReducer,

  termsAndConditionsReducer: termsAndConditionsReducer,
  combinationPackageStore: combinationPackageStore,
  tourMealTypeReducer: tourMealTypeReducer,
  tourPackageCreatePayloadReducers: tourPackageCreatePayloadReducers,
});

export default rootReducer;

// manage loading state
// const loadingReducer = (state = false, action) => {
//     switch (action.type) {
//         case "LOADING":
//         return true;
//         case "LOADED":
//         return false;
//         default:
//         return state;
//     }
//     };
//     // manage error state
//     const errorReducer = (state = null, action) => {
//     switch (action.type) {
//         case "ERROR":
//         return action.payload;
//         case "CLEAR_ERROR":
//         return null;
//         default:
//         return state;
//     }
//     }
// combine reducers and clear state if user is signed out
// const rootReducer = combineReducers({
// loadingReducer: loadingReducer,
// errorReducer: errorReducer,
// userDetailsReducer: userDetailsReducer,
// signupReducer: signUpReducer,
// signinReducer: signInReducer,
// ownerReducer: ownerReducer,
// contractReducer: contractReducer,
// hotelPropertyReducer: hotelPropertyReducer,
// categoryReducer: categoryReducer,
// submitReviewReducer: submitReviewReducer,
// fetchSummeryReducer: fetchSummeryReducer,
// createRoleReducer: createRoleReducer,
// fetchRoleReducer: fetchRoleReducer,
// editRoleReducer: createFunctionReducer,
// deleteRoleReducer: createFunctionReducer,
// createFunctionReducer: createFunctionReducer,
// fetchFunctionReducer: fetchFunctionReducer,
// editFunctionReducer: createFunctionReducer,
// deleteFunctionReducer: createFunctionReducer,
// });
